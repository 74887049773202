import React, { useEffect, useState } from "react";
import dashboardlogo from "../../assets/images/hubshift-logo1.png";
import Box from "@mui/material/Box";
import "../../assets/css/dashboard.css";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import NotificationsIcon from "@mui/icons-material/Notifications";
import exploreimg from "../../assets/images/searchmarket.svg";
import Help from "../../assets/images/Help.png";
import signout from "../../assets/images/sign-out.svg";
import Shiftnotes from "../../assets/images/Shiftnotes.svg";
import Appdashboard from "../../assets/images/Appdashboard.svg";
import quicktasks from "../../assets/images/quicktasks.svg";
import Setings from "../../assets/images/Settings-Icon.svg";
import profileImage from "../../assets/images/profile-icon.png";
import Badge from "@mui/material/Badge";
// import { Button } from "@material-ui/core";
import Notification from "../../components/ui/Notification";
import Attendance from "../../../src/pages/CommanComponant/Attendance/Attendance";
import HelpModel from "../../../src/Website/Help/Help-Model";
import localstorageData from "../../helpers/localstorageData";
import getClientId from "../../helpers/getClientId";
// import { Link } from "react-router-dom";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import {
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Cookies from "js-cookie";
import { ReactComponent as ChatIcon } from "../../assets/images/Chat_Default.svg";
import { ReactComponent as AttendanceIcon } from "../../assets/images/attendance.svg";
import PlanIcon from "../../assets/images/plan-icon.svg";
// import   "../../assets/css/Sa-style.css";

// import crypto from "crypto";
import CryptoJS from "crypto-js";
import {
  onCallStatus,
  getuserDetail,
  getHealthCarerActiveAP,
  getServiceProviderDetailsByUserId,
  getHealthCarerDetails,
  getAllUnreadNotifications,
  getHealthCarerPackageDetailsByUserId,
  GetClientIdListFromStakeholder,
  GetClientIdListFromFamilyMember,
  getParentLogoByTypeAndId,
  getSupportCoordinatorDetailsByUserid,
} from "../../api";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/noImage.png";
// import { styled } from '@mui/material/styles';

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
// import MenuItem from '@mui/material/MenuItem';
import MenuList from "@mui/material/MenuList";
import io from "socket.io-client";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    enterDelay={0}
    leaveDelay={0}
    placement="top-start"
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    position: "relative !important",
    zIndex: 999,
  },
}));

export default function Appbar() {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [show, setShow] = React.useState(true);
  const user = localstorageData();
  const clientId = getClientId();
  const [oncall, setOncall] = useState(false);
  const [selectedClientCreatedBy, setClientCreatedBy] = useState("");
  const [selectedClientSignupBy, setClientsignUpBy] = useState("");
  const [clientIdOfStake, setClientIdOfStake] = useState([]);
  const [clientIdOfFamilyMember, setClientIdOfFamilyMember] = useState([]);
  const [clientObject, setClientObject] = useState([]);
  const [stakHolderObject, setStakHolderObject] = useState([]);
  const [apId, setActiveAp] = useState();
  const [sPDataPlan, setSPDataPlan] = useState("");
  const [isFreeTrialActivated, setisFreeTrialActivated] = useState(true);

  const [isFreeTrialExpired, setisFreeTrialExpired] = useState(false);

  const [sPExpiryDate, setSPExpiryDate] = useState("");
  const [sPCompanyLogo, setSPCompanyLogo] = useState("");
  const [ScCompanyLogo, setScCompanyLogo] = useState("");
  const [spUserName, setSpUserName] = useState("");
  const [clientChipMesageText, setClientChipMesageText] = useState();
  const [notifications, setNotifications] = useState([]);
  const [userImage, setUserImage] = useState([]);
  const [chatReceivedCount, setchatReceivedCount] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedClientCreatedById = localStorage.getItem(
    "selectedClientCreatedBy"
  );
  const setClientId = (id) => {
    localStorage.setItem("clientId", id);
    localStorage.setItem("selectedClientCreatedBy", selectedClientCreatedBy);
    localStorage.setItem("selectedClientSignupBy", selectedClientSignupBy);
    window.location.reload();
  };

  const updateActiveInactive = (recordid, usrStatus) => {
    let messageVal = "";
    let alertVal = "";
    if (usrStatus === true) {
      messageVal = "Yes, activate it !";
      alertVal = "Oncall has been Activated.";
    } else if (usrStatus === false) {
      messageVal = "Yes, inactive it !";
      alertVal = "Oncall has been Inactivated.";
    }
    return Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: messageVal,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .put(`${onCallStatus}/${recordid}/${usrStatus}`)
          .then((res) => {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
        Swal.fire(usrStatus, alertVal, "success");
      }
    });
  };

  const chatAppUrl = process.env.REACT_APP_CHAT_APP_LIVE_URL_BACKEND;

  const isProduction = process.env.REACT_APP_SERVER_TYPE === "PRODUCTION";

  const socketProtocol = isProduction ? "wss://" : "ws://";

  const socketUrl = chatAppUrl
    ? `${socketProtocol}${chatAppUrl.replace(/^https?:\/\//, "")}`
    : null;
  //console.log("socket chatAppUrl", chatAppUrl);

  useEffect(() => {
    let socket;
    if (process.env.REACT_APP_SERVER_TYPE === "PRODUCTION") {
      if (chatAppUrl) {
        try {
          socket = io(socketUrl, {
            path: "/socket.io",
            transports: ["websocket"],
            auth: {
              token: user?.token,
            },
          });

          // Join the room for the specific user
          socket.emit("join", user?._id);

          // Listen for the unread message count event
          socket.on("messageReceived", (data) => {
            fetchUserDetailsData();
          });
        } catch (error) {
          console.error("Failed to connect to the chat server:", error);
        }
      } else {
        console.warn(
          "Chat server URL is not defined. Skipping socket connection."
        );
      }
    }
    //console.log("socket useEffect called", socketUrl);

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [user, chatAppUrl]);

  const fetchUserDetailsData = async () => {
    await axios
      .get(`${getuserDetail}/${user._id}`)
      .then(function (res) {
        if (res) {
          // setOncall(res?.data?.data?.onCall);
          // setClientObject(res?.data?.data?.clientId);
          // setStakHolderObject(res?.data?.data?.stackholderOfUserId);
          // setUserImage(res?.data?.data?.image?.location);
          setchatReceivedCount(res?.data?.data?.chatReceivedCount);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //console.log("socket chatReceivedCount", chatReceivedCount);

  const getSpUserNameData = async () => {
    await axios
      .get(`${getuserDetail}/${selectedClientCreatedBy}`)
      .then(function (res) {
        if (res) {
          setSpUserName(res?.data?.data?.userType);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (selectedClientCreatedBy) {
      getSpUserNameData();
    }
  }, [selectedClientCreatedBy]);

  useEffect(() => {
    fetchUserDetailsData();
    getClientIdListFromStakeholderData();
    getClientIdListFromFamilyMemberData();
    getuserDetailData();
    getHealthCarerActiveAPData();
    GetClientIdListFromStakeholderData();
  }, []);

  useEffect(() => {
    if (clientId) {
      getUserDetailForClient();
    }
  }, [clientId]);

  const GetClientIdListFromStakeholderData = async () => {
    await axios
      .get(`${GetClientIdListFromStakeholder}/${user?._id}`)
      .then(function (res) {
        if (res) {
          setClientIdOfStake(res?.data?.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getClientIdListFromStakeholderData = async () => {
    await axios
      .get(`${GetClientIdListFromStakeholder}/${user?._id}`)
      .then(function (res) {
        if (res) {
          setClientIdOfStake(res?.data?.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getClientIdListFromFamilyMemberData = async () => {
    await axios
      .get(`${GetClientIdListFromFamilyMember}/${user?._id}`)
      .then(function (res) {
        if (res) {
          setClientIdOfFamilyMember(res?.data?.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getuserDetailData = async () => {
    await axios
      .get(`${getuserDetail}/${user._id}`)
      .then(function (res) {
        if (res) {
          setOncall(res?.data?.data?.onCall);
          setClientObject(res?.data?.data?.clientId);
          setStakHolderObject(res?.data?.data?.stackholderOfUserId);
          setUserImage(res?.data?.data?.image?.location);
          //setchatReceivedCount(res?.data?.data?.chatReceivedCount);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Krisha please research on it then only continue with the following code
  // useEffect(() => {
  //   const fetchInterval = setInterval(() => {
  //     axios
  //       .get(`${getuserDetail}/${user._id}`)
  //       .then(function (res) {
  //         if (res) {
  //           setchatReceivedCount(res?.data?.data?.chatReceivedCount);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }, 1 * 60 * 1000); // Fetch notifications every 2 minutes
  // });

  const getUserDetailForClient = async () => {
    await axios
      .get(`${getuserDetail}/${clientId}`)
      .then(function (res) {
        if (res) {
          //console.log("resresres", res?.data?.data);
          setClientCreatedBy(res?.data?.data?.createdBy);
          setClientsignUpBy(res?.data?.data?.signUpBy);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getHealthCarerActiveAPData = async () => {
    await axios
      .get(`${getHealthCarerActiveAP}/${user._id}`)
      .then(function (res) {
        if (res) {
          setActiveAp(res?.data?.data[0]?._id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getHealthCarerDetailsData = async () => {
    await axios
      .get(`${getHealthCarerDetails}/${user._id}`)
      .then((res) => {
        let dataval = res?.data?.data;
        setSPCompanyLogo(dataval?.companyDetails?.logoPath?.location);
        setSPExpiryDate(dataval?.packageExpiryDate);
        setSPDataPlan(dataval?.packagesDetails?.name);
        setisFreeTrialActivated(dataval?.isFreeTrialActivated);
        setisFreeTrialExpired(dataval?.isFreeTrialExpired);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let clientChipMesage;
  let spUserId = user._id;
  if (user.createdByUserType === "serviceProvider") {
    spUserId = user.createdBy;
  }

  const getServiceProviderDetailsByUserIdData = async () => {
    await axios
      .get(`${getServiceProviderDetailsByUserId}/${spUserId}`)
      .then((res) => {
        let dataval = res?.data?.data[0];
        setSPCompanyLogo(dataval?.companyDetails?.logoPath?.location);
        setSPExpiryDate(dataval?.packageExpiryDate);
        setSPDataPlan(dataval?.packagesDetails?.name);
        localStorage.setItem("setSPPackageMenuId", dataval?.packageMenuId);
        setisFreeTrialActivated(dataval?.isFreeTrialActivated);
        setisFreeTrialExpired(dataval?.isFreeTrialExpired);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserDetailForClientData = async () => {
    await axios
      .get(`${getuserDetail}/${user._id}`)
      .then(function (res) {
        if (res) {
          let clientActiveStep = res?.data?.data?.activeStep;
          if (
            clientActiveStep === 4 &&
            res?.data?.data?.userStatus === "Approved"
          ) {
            clientChipMesage = "";
          } else {
            clientChipMesage = "Evaluation Ongoing";
          }
          setClientChipMesageText(clientChipMesage);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      user._id !== null &&
      (user.role === "serviceProvider" ||
        user.createdByUserType === "serviceProvider")
    ) {
      getServiceProviderDetailsByUserIdData();
    } else if (user._id !== null && user.role === "healthCarer") {
      getHealthCarerDetailsData();
    } else if (user.role === "client") {
      getUserDetailForClientData();
      getClientData();
    } else if (user.role === "supportCoordinator") {
      getClientData();
    } else {
      getClientData();
    }
  }, [user._id !== null]);

  const getSpCompanyLogoData = async () => {
    await axios
      .get(`${getServiceProviderDetailsByUserId}/${selectedClientCreatedBy}`)
      .then((res) => {
        let dataval = res?.data?.data[0];
        setSPCompanyLogo(dataval?.companyDetails?.logoPath?.location);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (spUserName == "serviceProvider") {
      getSpCompanyLogoData();
    }
  }, [spUserName]);

  const getSCCompanyLogoData = async () => {
    let userId = user._id;
    if (user.createdByUserType == "supportCoordinator") {
      userId = user.createdBy;
    }
    await axios
      .get(`${getSupportCoordinatorDetailsByUserid}/${userId}`)
      .then((res) => {
        let dataval = res?.data?.data[0];
        setScCompanyLogo(dataval?.organization?.logo?.location);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (
      user.role == "supportCoordinator" ||
      user.createdByUserType == "supportCoordinator"
    ) {
      getSCCompanyLogoData();
    }
  }, []);

  const getClientData = () => {
    axios
      .get(
        `${getHealthCarerPackageDetailsByUserId}/${user._id}?role=${user.role}`
      )
      .then((res) => {
        //console.log("dataval--", res?.data);
        let dataval = res?.data?.data[0];
        setSPExpiryDate(dataval?.packageExpiryDate);
        setSPDataPlan(dataval?.packagesDetails?.name);
        setisFreeTrialActivated(dataval?.isFreeTrialActivated);
        setisFreeTrialExpired(dataval?.isFreeTrialExpired);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
  const fetchNotifications = async () => {
    try {
      axios
        .get(`${getAllUnreadNotifications}/${user._id}`)
        .then((res) => {
          setNotifications(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  let dateOfPlanExpiry, remainDays;
  if (sPExpiryDate !== "") {
    dateOfPlanExpiry = moment(sPExpiryDate).format("DD-MM-YYYY");
    remainDays = moment(sPExpiryDate).diff(moment(new Date()), "days");
  }

  const fieldSignIn = () => {
    window.location.href = "/";
  };

  const navigate = useNavigate();

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("clientId");

    navigate("/sign-in");
    window.location.reload();
    toast.success("You have successfully logged out!");
  };

  //console.log("user", user.role);
  const attendanceRedirect = () => {
    if (user.role === "healthCarer") {
      navigate("/health-carer/attendance");
    } else if (user.role === "supportCoordinator") {
      navigate("/support-coordinator/attendance");
    } else if (user.role === "client") {
      navigate("/client/attendance");
    } else if (user.role === "accountant") {
      navigate("/accountant/attendance");
    } else if (user.role === "itManager") {
      navigate("/it-manager/attendance");
    } else if (user.role === "supervisor") {
      navigate("/supervisor/attendance");
    } else if (user.role === "familyMember") {
      navigate("/family-member/attendance");
    } else if (user.role === "Stakeholder") {
      navigate("/stakeholder/attendance");
    } else if (user.role === "generalManager") {
      navigate("/general-manager/attendance");
    } else if (user.role === "serviceProvider") {
      navigate("/service-provider/attendance");
    } else if (user.role === "planManager") {
      navigate("/plan-manager/attendance");
    } else if (user.role === "hr") {
      navigate("/hr/attendance");
    } else if (user.role === "billing") {
      navigate("/billing/attendance");
    } else if (user.role === "admin") {
      navigate("/admin/attendance");
    }
  };
  const fieldMyAccount = () => {
    if (user.role === "healthCarer") {
      navigate("/health-carer/myaccount");
    } else if (user.role === "supportCoordinator") {
      navigate("/support-coordinator/myaccount");
    } else if (user.role === "client") {
      navigate("/client/myaccount");
    } else if (user.role === "accountant") {
      navigate("/accountant/myaccount");
    } else if (user.role === "itManager") {
      navigate("/it-manager/myaccount");
    } else if (user.role === "supervisor") {
      navigate("/supervisor/myaccount");
    } else if (user.role === "superAdmin") {
      navigate("/super-admin/myaccount");
    } else if (user.role === "familyMember") {
      navigate("/family-member/myaccount");
    } else if (user.role === "Stakeholder") {
      navigate("/stakeholder/myaccount");
    } else if (user.role === "generalManager") {
      navigate("/general-manager/myaccount");
    } else if (user.role === "serviceProvider") {
      navigate("/service-provider/myaccount");
    } else if (user.role === "planManager") {
      navigate("/plan-manager/myaccount");
    } else if (user.role === "hr") {
      navigate("/hr/myaccount");
    } else if (user.role === "billing") {
      navigate("/billing/myaccount");
    } else if (user.role === "admin") {
      navigate("/admin/myaccount");
    }
  };

  const fieldSetting = () => {
    console.log("fefrererr", user.role);
    if (
      user.role === "healthCarer" &&
      user.createdByUserType === "superAdmin"
    ) {
      navigate("/health-carer/settings");
    } else if (user.role === "supportCoordinator") {
      navigate("/support-coordinator/settings");
    } else if (user.role === "client") {
      navigate("/client/settings");
    } else if (user.role === "accountant") {
      navigate("/accountant/settings");
    } else if (user.role === "itManager") {
      navigate("/it-manager/settings");
    } else if (user.role === "supervisor") {
      navigate("/supervisor/settings");
    } else if (user.role === "superAdmin") {
      navigate("/super-admin/settings");
    } else if (user.role === "familyMember") {
      navigate("/family-member/settings");
    } else if (user.role === "Stakeholder") {
      navigate("/stakeholder/settings");
    } else if (user.role === "generalManager") {
      navigate("/general-manager/settings");
    } else if (user.role === "serviceProvider") {
      navigate("/service-provider/settings");
    } else if (user.role === "planManager") {
      navigate("/plan-manager/settings");
    } else if (user.role === "hr") {
      navigate("/hr/settings");
    } else if (user.role === "billing") {
      navigate("/billing/settings");
    } else if (user.role === "admin") {
      navigate("/admin/settings");
    }
  };

  let exploreurl = "";

  if (user.role === "healthCarer") {
    //  exploreurl = "/health-carer/exploremarket";
    exploreurl = "/health-carer/pickup-shifts";
  } else if (user.role === "supportCoordinator") {
    exploreurl = "/support-coordinator/exploremarket";
  } else if (user.role === "client") {
    exploreurl = "/client/exploremarket";
  } else if (user.role === "accountant") {
    exploreurl = "/accountant/exploremarket";
  } else if (user.role === "itManager") {
    exploreurl = "/it-manager/exploremarket";
  } else if (user.role === "supervisor") {
    exploreurl = "/supervisor/exploremarket";
  } else if (user.role === "superAdmin") {
    exploreurl = "/super-admin/exploremarket";
  } else if (user.role === "familyMember") {
    exploreurl = "/family-member/exploremarket";
  } else if (user.role === "Stakeholder") {
    exploreurl = "/stakeholder/exploremarket";
  } else if (user.role === "generalManager") {
    exploreurl = "/general-manager/exploremarket";
  } else if (user.role === "serviceProvider") {
    exploreurl = "/service-provider/exploremarket";
  } else if (user.role === "planManager") {
    exploreurl = "/plan-manager/exploremarket";
  } else if (user.role === "hr") {
    exploreurl = "/hr/exploremarket";
  } else if (user.role === "billing") {
    exploreurl = "/billing/exploremarket";
  } else if (user.role === "admin") {
    exploreurl = "/admin/exploremarket";
  }
  const redirectTochatPage = () => {
    const userDataString = localStorage.getItem("userData");

    if (userDataString) {
      const userData = JSON.parse(userDataString);

      // Set the user's email as a cookie named "nameUserdata7678"
      if (userData.email) {
        // Cookies.set("nameUserdata7678", userData.email, {
        //   domain: ".13.239.71.129",
        //   path: "/",
        //   sameSite: "None",
        //   secure: true,
        // });
      }
      // encrypt
      const secretKey = "?Spdg&7i"; // Replace with your secret key used for encryption
      const iv = "Static16ByteIV!!"; // Replace with your IV used for encryption

      const encrypted = CryptoJS.AES.encrypt(userData.email, secretKey, {
        iv: iv,
      }).toString();
      const data = encodeURIComponent(encrypted);

      // end
      const url = process.env.REACT_APP_CHAT_APP_LIVE_URL + "/?data=" + data;

      window.open(url, "_blank");
    }
  };

  // Dropdown Button

  const options = ["Elvish", "C100264", "C100264"];

  const [openbtn, setOpenbtn] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const btnhandleClick = () => {
    //console.log(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenbtn(false);
  };

  const handleToggle = () => {
    setOpenbtn((prevOpen) => !prevOpen);
  };

  const btnhandleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenbtn(false);
  };

  // {
  //   console.log(
  //     "isFreeTrialActivated",
  //     remainDays,
  //     sPExpiryDate
  //     // remainDays
  //   );
  // }

  const [parentLogo, setParentLogo] = useState(dashboardlogo);
  let createdByUserType = user?.createdByUserType;
  let parentId = user?.createdBy;
  useEffect(() => {
    if (
      createdByUserType == "serviceProvider" ||
      createdByUserType == "supportCoordinator"
    ) {
      axios
        .get(
          `${getParentLogoByTypeAndId}?parentId=${parentId}&createdByUserType=${createdByUserType}`
        )
        .then((res) => {
          //console.log(res?.data, "res?.data");
          let dataval = res?.data?.data?.ImgPath;
          setParentLogo(dataval?.location);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user._id !== null]);

  return (
    <Box id="appbar">
      <Box className="appbar d-flex align-items-center justify-content-between">
        {user.role === "serviceProvider" ||
        user.createdByUserType === "serviceProvider" ||
        (spUserName === "serviceProvider" &&
          sPCompanyLogo !== undefined &&
          sPCompanyLogo !== "") ? (
          <>
            {/* dashboard-logo  */}
            <Box className="sp-company-logo">
              <Link onClick={fieldSignIn}>
                <img src={sPCompanyLogo} alt="logo" />
              </Link>
            </Box>
          </>
        ) : (user.role === "supportCoordinator" ||
            user.createdByUserType === "supportCoordinator") &&
          ScCompanyLogo !== undefined &&
          ScCompanyLogo !== "" ? (
          <>
            <Box className="sp-company-logo">
              <Link onClick={fieldSignIn}>
                <img src={ScCompanyLogo} alt="logo" />
              </Link>
            </Box>
          </>
        ) : user.role === "superAdmin" ||
          user.createdByUserType === "superAdmin" ||
          spUserName === "superAdmin" ||
          (user.createdByUserType === "superAdmin" &&
            user.role !== "serviceProvider") ? (
          <Box className="dashboard-logo parent-logo">
            <Link onClick={fieldSignIn}>
              <img src={parentLogo} alt="logo" />
            </Link>
          </Box>
        ) : (
          <>
            <Box className="dashboard-logo">
              <Link onClick={fieldSignIn}>
                <img src={noImage} alt="logo" />
              </Link>
            </Box>
          </>
        )}

        <Box
          id="health-carer-appbar"
          className="appbar-menu d-flex align-items-center "
          spacing={1}
        >
          {user.role == "healthCarer" ? (
            <>
              {/* <Box className="hc-header-tooltip-wrapper">
                <HtmlTooltip
                  placement="top-start"
                  className="header-tooltip-box"
                  title={
                    <React.Fragment>
                      <Box className="d-flex align-items-center">
                        <Box className="hc-tooltip-imgWrapper">
                          <img
                            src={quicktasks}
                            className="tooltip-hc-icon"
                            alt="explore"
                          />
                        </Box>
                        <span>Quick Tasks</span>
                      </Box>
                    </React.Fragment>
                  }
                >
                  <Button className="tooltip-header-hc">
                    <Box ml={2}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Box className="tooltip-hc-iconImage">
                          <img src={quicktasks} alt="explore" />
                        </Box>
                      </Stack>
                    </Box>
                  </Button>
                </HtmlTooltip>
              </Box> */}

              {apId !== undefined ? (
                <Box
                // className="hc-header-tooltip-wrapper"
                >
                  {/* <HtmlTooltip
                    placement="top-start"
                    className="header-tooltip-box"
                    title={
                      <React.Fragment>
                        <Box className="d-flex align-items-center">
                          <Box className="hc-tooltip-imgWrapper">
                            <img
                              src={Appdashboard}
                              className="tooltip-hc-icon"
                              alt="explore"
                            />
                          </Box>
                          <span>Task Board</span>
                        </Box>
                      </React.Fragment>
                    }
                  > */}

                  {/* <Button className="tooltip-header-hc">
                      <Link
                        to={`/health-carer/appointment-details/dashboard?editId1=${apId}`}
                        state={apId}
                      >
                        <Box ml={2}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Box className="tooltip-hc-iconImage">
                              <img src={Appdashboard} alt="explore" />
                            </Box>
                          </Stack>
                        </Box>
                      </Link>
                    </Button> */}

                  <Link
                    to={`/health-carer/appointment-details/dashboard?editId1=${apId}`}
                    state={apId}
                  >
                    <Box className="char-header-icon">
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 0 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box className="tooltip-hc-iconImage">
                          <img src={Appdashboard} alt="explore" />
                        </Box>

                        <Typography
                          textAlign="center"
                          className="header-task-board"
                        >
                          {" "}
                          Task Board{" "}
                        </Typography>
                      </Stack>
                    </Box>
                  </Link>

                  {/* </HtmlTooltip> */}
                </Box>
              ) : (
                ""
              )}

              <Box
              // className="hc-header-tooltip-wrapper"
              >
                {/* <HtmlTooltip
                  placement="top-start"
                  className="header-tooltip-box"
                  title={
                    <React.Fragment>
                      <Box className="d-flex align-items-center">
                        <Box className="hc-tooltip-imgWrapper">
                          <img
                            src={Shiftnotes}
                            className="tooltip-hc-icon"
                            alt="explore"
                          />
                        </Box>
                        <span>Continue Shift Notes</span>
                      </Box>
                    </React.Fragment>
                  }
                 > */}

                {/* <Button className="tooltip-header-hc">
                    <Link to={"/health-carer/list-continue-shift"}>
                      <Box ml={2}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Box className="tooltip-hc-iconImage">
                            <img src={Shiftnotes} alt="explore" />
                          </Box>
                        </Stack>
                      </Box>
                    </Link>
                  </Button> */}

                <Link to={"/health-carer/list-continue-shift"}>
                  <Box className="char-header-icon">
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "column" }}
                      spacing={{ xs: 1, sm: 1, md: 0 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box className="tooltip-hc-iconImage">
                        <img src={Shiftnotes} alt="explore" />
                      </Box>

                      <Typography
                        textAlign="center"
                        className="header-task-board"
                      >
                        Continue Shift Notes
                      </Typography>
                    </Stack>
                  </Box>
                </Link>

                {/* </HtmlTooltip> */}
              </Box>

              {user?.isSoleTrader === false ? (
                <Box
                  className={`${
                    oncall ? "" : "inactive"
                  } appbar-menu-item char-header-icon`}
                >
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 0 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AntSwitch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      checked={oncall}
                      onChange={() =>
                        updateActiveInactive(user?._id, oncall ? false : true)
                      }
                    />
                    <Typography
                      // component="div"
                      //  className="appbar-switch-text"
                      textAlign="center"
                      paddingTop="7px"
                    >
                      On call
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                ""
              )}
              {/* {console.log(
                "isFreeTrialActivated",
                isFreeTrialActivated,
                remainDays
              )} */}
              {user?.createdByUserType === "superAdmin" ? (
                <Link
                  className="d-flex align-items-center routine-cell-task-btn"
                  to={"/health-carer/list-package-menu"}
                >
                  <Box className="char-header-icon">
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "column" }}
                      spacing={{ xs: 1, sm: 1, md: 0 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {
                        // <Link
                        //   className="d-flex align-items-center routine-cell-task-btn"
                        //   to={"/health-carer/list-package-menu"}
                        // >

                        <>
                          {/* {console.log(
                            "isFreeTrialActivated",
                            isFreeTrialActivated,
                            remainDays
                          )} */}
                          {isFreeTrialActivated && remainDays >= 0 ? (
                            <Badge
                              badgeContent={"FREE"}
                              color="primary"
                              className="sub-badge"
                            >
                              <img src={PlanIcon} alt="planicon" />
                            </Badge>
                          ) : remainDays < 0 ? (
                            <Badge
                              badgeContent={"EXPIRED"}
                              color="primary"
                              className="sub-expired-badge"
                            >
                              <img src={PlanIcon} alt="planicon" />
                            </Badge>
                          ) : (
                            <img src={PlanIcon} alt="planicon" />
                          )}
                          {/* <img src={PlanIcon} alt="planicon" /> */}
                          <Typography textAlign="center">
                            {remainDays >= 0 ? sPDataPlan : "No Active Plan"}{" "}
                          </Typography>
                        </>

                        // </Link>
                      }
                    </Stack>
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {user.role === "Stakeholder" && clientIdOfStake !== undefined ? (
            <>
              <div className="char-header-icon">
                <ButtonGroup
                  variant="contained"
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button
                    className="Sh-header-btn"
                    variant="contained"
                    onClick={btnhandleClick}
                  >
                    <Stack spacing={0}>
                      <div>
                        {clientIdOfStake &&
                        clientIdOfStake.length > 0 &&
                        selectedIndex >= 0 ? (
                          <>
                            {clientIdOfStake.map((client, index) => {
                              if (client._id === clientId) {
                                return (
                                  <div key={index}>
                                    <Typography
                                      color="#15a07d"
                                      fontWeight="bold"
                                    >
                                      {client.firstName}
                                    </Typography>
                                    {/* <Typography color="#15a07d">
                                      {client.employeeId}
                                    </Typography> */}
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </>
                        ) : (
                          "Stake holder"
                        )}
                      </div>
                    </Stack>
                  </Button>

                  <Button
                    variant="contained"
                    className="Sh-header-svg"
                    size="small"
                    aria-controls={openbtn ? "split-button-menu" : undefined}
                    aria-expanded={openbtn ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>

                {clientIdOfStake.map((value, index) => {
                  if (!clientId) {
                    if (index === 0) {
                      localStorage.setItem("clientId", value._id);
                      window.location.reload();
                    }
                  }

                  let st = { backgroundColor: "#e2ecf1" };
                  return (
                    <div key={index} style={st}>
                      <div style={{ position: "relative" }}>
                        <Popper
                          style={{ position: "absolute", zIndex: 999 }}
                          // sx={{
                          //   zIndex: 1,
                          // }}
                          // style={{ zIndex: 999 }}
                          open={openbtn}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <div
                                // style={{ position: "relative" }}
                                >
                                  <ClickAwayListener
                                    onClickAway={btnhandleClose}
                                  >
                                    <MenuList
                                      id="split-button-menu"
                                      autoFocusItem
                                    >
                                      {clientIdOfStake.map((value, index) => (
                                        <>
                                          <MenuItem
                                            key={value}
                                            selected={
                                              index ===
                                              clientIdOfStake.findIndex(
                                                (client) =>
                                                  client._id === clientId
                                              )
                                            }
                                            onClick={(event) => {
                                              handleMenuItemClick(event, index);
                                              setClientId(value._id);
                                            }}
                                          >
                                            <Stack spacing={1} direction="row">
                                              <div>
                                                <Typography
                                                  color="#3d4244"
                                                  fontWeight="bold"
                                                >
                                                  {value?.firstName}
                                                </Typography>
                                              </div>

                                              <div>
                                                <Typography color="#3d4244">
                                                  {value?.employeeId}
                                                </Typography>
                                              </div>
                                            </Stack>
                                          </MenuItem>
                                        </>
                                      ))}
                                    </MenuList>
                                  </ClickAwayListener>
                                </div>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </div>
                  ); // returning an empty fragment isn't necessary, you can return a div with the specified style
                })}
              </div>
            </>
          ) : null}

          {/* Static  */}

          {/* Static  */}

          {/* clientIdOfFamilyMember */}

          {user.role === "familyMember" &&
          clientIdOfFamilyMember !== undefined ? (
            <>
              <div className="char-header-icon">
                <ButtonGroup
                  // variant="outlined"
                  variant="contained"
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button
                    className="Sh-header-btn"
                    // variant="outlined"
                    variant="contained"
                    onClick={btnhandleClick}
                  >
                    <Stack spacing={0}>
                      <div>
                        {clientIdOfFamilyMember &&
                        clientIdOfFamilyMember.length > 0 ? (
                          <>
                            {clientIdOfFamilyMember.map((client, index) => {
                              if (client._id === clientId) {
                                return (
                                  <div key={index}>
                                    <Typography
                                      color="#3d4244"
                                      fontWeight="bold"
                                    >
                                      {client.firstName}
                                    </Typography>
                                    {/* <Typography color="#3d4244">
                                      {client.employeeId}
                                    </Typography> */}
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </>
                        ) : (
                          "Family Member"
                        )}
                      </div>
                    </Stack>
                  </Button>

                  <Button
                    // variant="outlined"
                    variant="contained"
                    className="Sh-header-svg"
                    size="small"
                    aria-controls={openbtn ? "split-button-menu" : undefined}
                    aria-expanded={openbtn ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>

                {clientIdOfFamilyMember.map((value, index) => {
                  if (!clientId) {
                    if (index === 0) {
                      localStorage.setItem("clientId", value._id);
                      window.location.reload();
                    }
                  }

                  let st = { backgroundColor: "#e2ecf1" };
                  return (
                    <div key={index} style={st}>
                      <div style={{ position: "relative" }}>
                        <Popper
                          style={{ position: "absolute", zIndex: 999 }}
                          // sx={{
                          //   zIndex: 1,
                          // }}
                          // style={{ zIndex: 999 }}
                          open={openbtn}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <div
                                  style={{ position: "relative", zIndex: 999 }}
                                >
                                  <ClickAwayListener
                                    onClickAway={btnhandleClose}
                                  >
                                    <MenuList
                                      id="split-button-menu"
                                      autoFocusItem
                                    >
                                      {clientIdOfFamilyMember.map(
                                        (value, index) => (
                                          <>
                                            <MenuItem
                                              key={value}
                                              selected={
                                                index ===
                                                clientIdOfFamilyMember.findIndex(
                                                  (client) =>
                                                    client._id === clientId
                                                )
                                              }
                                              onClick={(event) => {
                                                handleMenuItemClick(
                                                  event,
                                                  index
                                                );
                                                setClientId(value._id);
                                              }}
                                            >
                                              <Stack
                                                spacing={1}
                                                direction="row"
                                              >
                                                <div>
                                                  <Typography
                                                    color="#3d4244"
                                                    fontWeight="bold"
                                                  >
                                                    {value?.firstName}
                                                  </Typography>
                                                </div>

                                                <div>
                                                  <Typography color="#3d4244">
                                                    {value?.employeeId}
                                                  </Typography>
                                                </div>
                                              </Stack>
                                            </MenuItem>
                                          </>
                                        )
                                      )}
                                    </MenuList>
                                  </ClickAwayListener>
                                </div>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </div>
                  ); // returning an empty fragment isn't necessary, you can return a div with the specified style
                })}
              </div>
            </>
          ) : null}

          {user.role === "serviceProvider" &&
          sPDataPlan !== "" &&
          sPDataPlan !== undefined &&
          user?.createdByUserType === "superAdmin" ? (
            <>
              {/* <Box className="appbar-curentplan appbar-menu-item ">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Link
                    className="d-flex align-items-center routine-cell-task-btn"
                    to={"/service-provider/list-package-menu"}
                  >
                    <Typography className="curentplan-text">
                      {remainDays >= 0 ? sPDataPlan : "No Active Plan"} Plan
                    </Typography>
                  </Link>
                </Stack>
              </Box> */}

              <Link to={"/service-provider/list-package-menu"}>
                <Box className="char-header-icon">
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 0 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {isFreeTrialActivated && remainDays >= 0 ? (
                      <Badge
                        badgeContent={"FREE"}
                        color="primary"
                        className="sub-badge"
                      >
                        <img src={PlanIcon} alt="planicon" />
                      </Badge>
                    ) : remainDays < 0 ? (
                      <Badge
                        badgeContent={"EXPIRED"}
                        color="primary"
                        className="sub-expired-badge"
                      >
                        <img src={PlanIcon} alt="planicon" />
                      </Badge>
                    ) : (
                      <img src={PlanIcon} alt="planicon" />
                    )}
                    {/* <img src={PlanIcon} alt="planicon" /> */}

                    <Typography>
                      {remainDays >= 0 ? sPDataPlan : "No Active"} Plan
                    </Typography>
                  </Stack>
                </Box>
              </Link>

              {/* <Link to={`/service-provider/quick-task`} state={apId}>
                <Box className="char-header-icon">
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 0 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box className="tooltip-hc-iconImage">
                      <img src={Appdashboard} alt="explore" />
                    </Box>

                    <Typography
                      textAlign="center"
                      className="header-task-board"
                    >
                      {" "}
                      Task DashBoard{" "}
                    </Typography>
                  </Stack>
                </Box>
              </Link> */}
            </>
          ) : (
            ""
          )}

          {user.role === "client" &&
          sPDataPlan !== "" &&
          sPDataPlan !== undefined &&
          user?.createdByUserType === "superAdmin" ? (
            // && clientChipMesageText === "Evaluation Ongoing"
            <>
              {/* <Box className="appbar-curentplan appbar-menu-item ">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Link
                    className="d-flex align-items-center routine-cell-task-btn"
                    to={"/client/list-package-menu"}
                  >
                    <Typography className="curentplan-text">
                      {remainDays >= 0 ? sPDataPlan : "No Active Plan"} Plan
                    </Typography>
                  </Link>
                </Stack>
              </Box> */}

              <Link to={"/client/list-package-menu"}>
                <Box className="char-header-icon">
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 0 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {isFreeTrialActivated && remainDays >= 0 ? (
                      <Badge
                        badgeContent={"FREE"}
                        color="primary"
                        className="sub-badge"
                      >
                        <img src={PlanIcon} alt="planicon" />
                      </Badge>
                    ) : remainDays < 0 ? (
                      <Badge
                        badgeContent={"EXPIRED"}
                        color="primary"
                        className="sub-expired-badge"
                      >
                        <img src={PlanIcon} alt="planicon" />
                      </Badge>
                    ) : (
                      <img src={PlanIcon} alt="planicon" />
                    )}

                    <Typography>
                      {remainDays >= 0 ? sPDataPlan : "No Active Plan"}
                    </Typography>
                  </Stack>
                </Box>
              </Link>
            </>
          ) : (
            ""
          )}

          {user.role === "supportCoordinator" &&
          sPDataPlan !== "" &&
          sPDataPlan !== undefined ? (
            <>
              {/* <Box className="appbar-curentplan appbar-menu-item ">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Link
                    className="d-flex align-items-center routine-cell-task-btn"
                    to={"/support-coordinator/list-package-menu"}
                  >
                    <Typography className="curentplan-text">
                      {remainDays >= 0 ? sPDataPlan : "No Active Plan"} Plan
                    </Typography>
                  </Link>
                </Stack>
              </Box> */}

              {user?.isSoleTrader === true ? (
                <>
                  <Link to={"/support-coordinator/list-package-menu"}>
                    <Box className="char-header-icon">
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 0 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {isFreeTrialActivated && remainDays >= 0 ? (
                          <Badge
                            badgeContent={"FREE"}
                            color="primary"
                            className="sub-badge"
                          >
                            <img src={PlanIcon} alt="planicon" />
                          </Badge>
                        ) : remainDays < 0 ? (
                          <Badge
                            badgeContent={"EXPIRED"}
                            color="primary"
                            className="sub-expired-badge"
                          >
                            <img src={PlanIcon} alt="planicon" />
                          </Badge>
                        ) : (
                          <img src={PlanIcon} alt="planicon" />
                        )}
                        <Typography textAlign="center">
                          {remainDays >= 0 ? sPDataPlan : "No Active Plan"}
                        </Typography>
                      </Stack>
                    </Box>
                  </Link>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}

          {user.role === "client" &&
          clientChipMesageText === "Evaluation Ongoing" ? (
            <>
              <Link to={"/client/exploremarket"}>
                <Box className="char-header-icon">
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 0 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img src={exploreimg} alt="explore" width="26px" />
                    <Typography textAlign="center">Marketplace</Typography>
                  </Stack>
                </Box>
              </Link>
            </>
          ) : (
            ""
          )}

          {(user?.role == "healthCarer" && user?.isSoleTrader === true) ||
          (user.role == "client" &&
            clientChipMesageText != "Evaluation Ongoing") ? (
            <Link to={exploreurl}>
              <Box className="char-header-icon">
                <Stack
                  direction={{ xs: "column", sm: "column", md: "column" }}
                  spacing={{ xs: 1, sm: 1, md: 0 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={exploreimg} alt="explore" width="26px" />
                  <Typography textAlign="center">Marketplace</Typography>
                </Stack>
              </Box>
            </Link>
          ) : (
            ""
          )}

          {user.role !== "superAdmin" &&
          user.role !== "client" &&
          user.role !== "Stakeholder" &&
          user.role !== "familyMember" &&
          user?.createdByUserType !== "superAdmin" &&
          user?.isSoleTrader !== true ? (
            <>
              <Box className="char-header-icon" onClick={attendanceRedirect}>
                {/* <Link to="../../components/email/chatAi.js"> */}
                <Stack
                  direction={{ xs: "column", sm: "column", md: "column" }}
                  spacing={{ xs: 1, sm: 1, md: 0 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <AttendanceIcon />{" "}
                  <Typography textAlign="center"> Time Card </Typography>
                </Stack>
                {/* </Link> */}
              </Box>

              {/* Ramdam subscription plan code  components here */}

              {/* <Box className="char-header-icon" >

             <Button
                          size="medium"
                        
                          className="subscribe-button-header"
                          type="submit"
                          variant="contained"
                          
                        >
                          Start Your Free Trial
                         
                        </Button>

                        </Box> */}
            </>
          ) : (
            ""
          )}

          {/* /// */}

          {/* // */}
          {/* <Box className="appbar-notification appbar-menu-item">
              <Notification />
          </Box> */}

          {user.role === "Stakeholder" || user.role === "familyMember" ? (
            <>
              <Box
                className="char-header-icon"
                style={{ cursor: "pointer" }}
                title="No Access"
              >
                <Stack
                  direction={{ xs: "column", sm: "column", md: "column" }}
                  spacing={{ xs: 1, sm: 1, md: 0 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {chatReceivedCount > 0 ? (
                    <>
                      {" "}
                      <Badge
                        badgeContent={chatReceivedCount}
                        color={"secondary"}
                      ></Badge>{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  <ChatIcon />
                  <Typography textAlign="center">Chat</Typography>
                </Stack>
              </Box>
            </>
          ) : (
            <>
              <Box
                className="char-header-icon"
                style={{ cursor: "pointer" }}
                onClick={redirectTochatPage}
              >
                <Stack
                  direction={{ xs: "column", sm: "column", md: "column" }}
                  spacing={{ xs: 1, sm: 1, md: 0 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {chatReceivedCount > 0 ? (
                    <>
                      {" "}
                      <Badge
                        badgeContent={chatReceivedCount}
                        color={"secondary"}
                      ></Badge>{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  <ChatIcon />
                  <Typography textAlign="center">Chat</Typography>
                </Stack>
              </Box>
            </>
          )}

          <Box className="char-header-icon">
            <Stack
              direction={{ xs: "column", sm: "column", md: "column" }}
              spacing={{ xs: 1, sm: 1, md: 0 }}
              justifyContent="center"
              alignItems="center"
            >
              <Notification />

              <Typography
                textAlign="center"
                //  className="notification-header-icon"
              >
                Notification
              </Typography>
            </Stack>
          </Box>
          <Box className="char-header-icon mb-1">
            <Stack
              direction={{ xs: "column", sm: "column", md: "column" }}
              spacing={{ xs: 1, sm: 1, md: 0 }}
              justifyContent="center"
              alignItems="center"
            >
              <HelpModel />

              <Typography textAlign="center">Help</Typography>
            </Stack>
          </Box>

          {/* <Box
            className="appbar-notification appbar-menu-item"
            onClick={redirectTochatPage}
          >
            <ChatIcon />
          </Box> */}

          {/* className='d-flex align-items-center justify-content-between' */}

          <Box className="d-flex align-items-center header-myaccount">
            {/* <Box onClick={handleClick} className="header-profile-image">
              <img src={userImage ? userImage : noImage} alt="profile" />
            </Box> */}

            <Tooltip>
              <IconButton sx={{ p: 0, mr: 2 }}>
                <Box onClick={handleClick} className="header-profile-image">
                  <Avatar
                    src={userImage ? userImage : noImage}
                    alt="profile"
                  ></Avatar>
                </Box>
              </IconButton>
            </Tooltip>

            <ArrowDropDownIcon
              onClick={handleClick}
              className="hc-header-profileIcon"
            />
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={fieldMyAccount}>
              {" "}
              <Avatar
                src={userImage ? userImage : noImage}
                alt="profile"
                className="profile-dd-avtar mr-2"
              ></Avatar>
              My Account
            </MenuItem>

            {user.role === "supportCoordinator" &&
            user?.isSoleTrader === false ? (
              <></>
            ) : (
              <>
                {user.role !== "supportCoordinator" &&
                user.role !== "healthCarer" &&
                user.role !== "client" &&
                user.role !== "serviceProvider" &&
                user.role !== "admin" &&
                user.role !== "superAdmin" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    {user.role == "supportCoordinator" &&
                    user?.isSoleTrader === true ? (
                      <MenuItem onClick={fieldSetting}>
                        <img src={Setings} alt="logo" className="mr-2" />
                        Settings
                      </MenuItem>
                    ) : (
                      <>
                        {" "}
                        {(user?.role === "supportCoordinator" &&
                          (user?.isSoleTrader === true ||
                            user?.createdByUserType === "superAdmin")) ||
                        (user?.role === "healthCarer" &&
                          user?.isSoleTrader === true &&
                          user.createdByUserType === "superAdmin") ||
                        (user?.role === "client" &&
                          (user?.isSoleTrader === true ||
                            user?.createdByUserType === "superAdmin")) ||
                        (user?.role === "serviceProvider" &&
                          (user?.isSoleTrader === true ||
                            user?.createdByUserType === "superAdmin")) ||
                        user?.role === "admin" ||
                        user?.role === "superAdmin" ? (
                          <MenuItem onClick={fieldSetting}>
                            <img src={Setings} alt="logo" className="mr-2" />
                            Settings
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </>
                    )}{" "}
                  </>
                )}
              </>
            )}

            {/* <MenuItem>
              {" "}
              <img src={Help} alt="logo" className="mr-2" />
            
              Help
            
            </MenuItem> */}
            <MenuItem onClick={fieldLogout}>
              {" "}
              <img src={signout} alt="logo" className="mr-2" />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}
