import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
// import { Link } from "@mui/material";
import { Button, Avatar } from "@mui/material";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/web-css.css";
import "../../../assets/css/Sa-style.css";
import "../../../assets/css/Appointment.css";
import "../../../assets/css/custom.css";
import "../../../App.css";
import axios from "axios";
import noImage from "../../../assets/images/noImage.png";
import { useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import MapImage from "../../../assets/images/google-maps-svg.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate, Link } from "react-router-dom";
import SILSDA from "../../../assets/images/SDA.png";
import CommunityAccess from "../../../assets/images/client-Community Access.png";
import Homecare from "../../../assets/images/client-Homecare.png";
import STAClient from "../../../assets/images/client-STA.png";
import pricingIcon from "../../../assets/images/pricing-tick-icon.png";
import {
  getDetailsPublicRosterAppointment,
  getHCLikedAppointment,
  setIndvHCinAppt,
  setFavAppointment,
  getClientRating,
  getIndvHCinAppt,
  getClientRiskAssesment,
} from "../../../api";

import { GoogleMap, Marker } from "@react-google-maps/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GradeIcon from "@mui/icons-material/Grade";

import WebFooter from "../../../Website/Web-footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const getIconForCategory = (categoryName) => {
  switch (categoryName) {
    case 'Community Access':
      return CommunityAccess;
    case 'Homecare':
      return Homecare;
    case 'Respite/STA':
      return STAClient;
    case 'SIL/SDA':
      return SILSDA;
    default:
      return null; 
  }
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewDetail = (props) => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  let isSoleTrader = userData?.isSoleTrader;

  const [isLoading, setLoading] = useState(true);
  const [tabValue, settabValue] = React.useState(0);
  const [favourite, setFavourite] = useState([]);
  const mapRef = useRef(null);
  const [disableButton, setDisableButton] = useState(false);

  const [clientDetails, setClientDetails] = useState([]);
  const [clientUserData, setClientUserData] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [healthIssueData, setHealthIssueData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [userTypeData, setUserTypeData] = useState([]);
  const [sPData, setSPData] = useState([]);
  const [services, setServices] = useState();
  const [apptHc, setApptHc] = useState();
  const [dateVal, setDateVal] = useState();

  const [stateData, setStateData] = useState();
  const [stateDataComm, setStateDataComm] = useState();
  const [stateDataHome, setStateDataHome] = useState();
  const [stateDataResp, setStateDataResp] = useState();
  const [stateDataSil, setStateDataSil] = useState();

  const [hcCheckId, setHCCheck] = useState();

  const handleChange = (event, newValue) => {
    settabValue(newValue);
  };

  const location = useLocation();

  const clientId = location?.state?.clientId;
  const ApptId = location?.state?.ApptId;
  let pathValue = location?.state?.pathValue;
  const insidePropsValue = props?.props?.page;
  if (insidePropsValue !== undefined) {
    pathValue = insidePropsValue;
  }

  useEffect(() => {
    GetApptHc();
  }, [ApptId]);

  const GetApptHc = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${getIndvHCinAppt}/${ApptId}`);
      let dataval = res?.data?.data?.requestedIndHCId;
      setApptHc(dataval);
    } catch (error) {
      console.log(error);
    }
  };

  //getIndvHCinAppt
  useEffect(() => {
    GetClientDetails();
  }, [ApptId]);

  const GetClientDetails = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${getDetailsPublicRosterAppointment}/${ApptId}`
      );
      let dataval = res?.data?.data[0];
      let clientUserData = dataval?.userDetails;

      setClientDetails(dataval?.userData);
      setMedicationData(dataval?.MedicationData);
      setHealthIssueData(dataval?.healthissuesData);
      setClientUserData(clientUserData);
      setLocationData(dataval?.Valcategories);
      setUserTypeData(dataval?.userTypeData);
      setSPData(dataval?.serviceProvider);
      setDateVal(dataval?.appointmentDateAndTime);
      setServices(dataval?.userTypeData?.serviceCategoryObject)
      setStateData(dataval?.stateDetails?.name);
      setStateDataComm(dataval?.stateDetailsComm?.name);
      setStateDataHome(dataval?.stateDetailsHome?.name);
      setStateDataResp(dataval?.stateDetailsResp?.name);
      setStateDataSil(dataval?.stateDetailsSil?.name);

      setHCCheck(dataval?.appointedIndHCId);
    } catch (error) {
      console.log(error);
    }
  };

  const addLikeApptFunction = async (ApptId, hcId, type) => {
    try {
      if (isSoleTrader === true) {
        axios
          .put(`${setFavAppointment}/${ApptId}/${hcId}/${type}`)
          .then((res) => {
            toast.success(res.data.message);
            GetClientDetails();
            HCLikeDislike();
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Only ABN Holders Can Shortlist");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const addRequestFunction = async (ApptId, hcId, type) => {
    try {
      setDisableButton(true);
      if (isSoleTrader === true) {
        axios
          .put(`${setIndvHCinAppt}/${ApptId}/${hcId}/${type}/undefined/undefined`)
          .then((res) => {
            toast.success(res.data.message);

            if (type == "send") {
              setTimeout(() => {
                navigate("/health-carer/appointment-connection-list");
              }, 500);
            } else {
              setDisableButton(false);
              GetClientDetails();
              GetApptHc();
            }
          })
          .catch((error) => {
            setDisableButton(false);
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Only ABN Holders Can Send Request");
      }
    } catch (error) {
      setDisableButton(false);
      toast.error("Something went wrong");
    }
  };

  const RedirectDirectFunction = async (appointmentId) => {
    try {
      setTimeout(() => {
        navigate("/health-carer-login", {
          state: { ApptId: appointmentId },
        });
      }, 500);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    HCLikeDislike();
  }, []);

  const HCLikeDislike = async () => {
    try {
      axios
        .get(`${getHCLikedAppointment}/${userData?._id}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setFavourite(listUserTypeList?.likedAppointment);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const [rating, setRating] = useState(0);
  useEffect(() => {
    CheckClientRating();
  }, []);

  const CheckClientRating = async () => {
    try {
      axios
        .get(`${getClientRating}/${clientId}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setRating(
            listUserTypeList?.roundedAverageRating !== null
              ? listUserTypeList?.roundedAverageRating
              : 0
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const renderRatingIcons = () => {
    const ratingIcons = [];

    for (let i = 0; i < rating; i++) {
      ratingIcons.push(<GradeIcon key={i} className="rating-cion-sty" />);
    }

    return rating > 0 ? ratingIcons : "";
  };

  let residentailAddress,
    homeAddress,
    communityAddress,
    respiteddress,
    silSdaAddress,
    displayAddress;

  residentailAddress = clientUserData?.address
    ? clientUserData?.address?.addressLine1 +
    ", " +
    clientUserData?.address?.city +
    ", " +
    stateData +
    ", " +
    clientUserData?.address?.postalCode
    : "";

  homeAddress =
    clientDetails?.homeCare?.length > 0
      ? clientDetails?.homeCare[0]?.addressLine1 +
      ", " +
      clientDetails?.homeCare[0]?.city +
      ", " +
      stateDataHome +
      ", " +
      clientDetails?.homeCare[0]?.postalCode
      : "";

  communityAddress =
    clientDetails?.communityAccess?.address?.length > 0
      ? clientDetails?.communityAccess?.address[0]?.addressLine1 +
      ", " +
      clientDetails?.communityAccess?.address[0]?.city +
      ", " +
      stateDataComm +
      ", " +
      clientDetails?.communityAccess?.address[0]?.postalCode
      : "";

  respiteddress =
    clientDetails?.respiteAndsta?.address?.length > 0
      ? clientDetails?.respiteAndsta?.address[0]?.addressLine1 +
      ", " +
      clientDetails?.respiteAndsta?.address[0]?.city +
      ", " +
      stateDataResp +
      ", " +
      clientDetails?.respiteAndsta?.address[0]?.postalCode
      : "";

  silSdaAddress =
    clientDetails?.silAndSda?.address?.length > 0
      ? clientDetails?.silAndSda?.address[0]?.addressLine1 +
      ", " +
      clientDetails?.silAndSda?.address[0]?.city +
      ", " +
      stateDataSil +
      ", " +
      clientDetails?.silAndSda?.address[0]?.postalCode
      : "";

  //displayAddress

  let homeAddressCord,
    communityAddressCord,
    respiteddressCord,
    silSdaAddressCord,
    displayCord;

  homeAddressCord =
    clientDetails?.homeCare?.length > 0
      ? clientDetails?.homeCare[0]?.homeCareCoordinate?.coordinates
      : "";

  communityAddressCord =
    clientDetails?.communityAccess?.address?.length > 0
      ? clientDetails?.communityAccess?.address[0]?.communityAccessCoordinate
        ?.coordinates
      : "";

  respiteddressCord =
    clientDetails?.respiteAndsta?.address?.length > 0
      ? clientDetails?.respiteAndsta?.address[0]?.respiteAndstaCoordinate
        ?.coordinates
      : "";

  silSdaAddressCord =
    clientDetails?.silAndSda?.address?.length > 0
      ? clientDetails?.silAndSda?.address[0]?.silAndSdaCoordinate?.coordinates
      : "";

  switch (locationData?.name) {
    case "Homecare":
      displayAddress = homeAddress;
      displayCord = homeAddressCord;

      break;
    case "Community Access":
      displayAddress = communityAddress;
      displayCord = communityAddressCord;

      break;
    case "Respite/STA":
      displayAddress = respiteddress;
      displayCord = respiteddressCord;

      break;
    case "SIL/SDA":
      displayAddress = silSdaAddress;
      displayCord = silSdaAddressCord;

      break;
    default:
      displayAddress = "";
      displayCord = "";
  }

  const mapStyles = {
    height: "332px",
    width: "100%",
  };

  const isValidCoordinates = (coords) => {
    return (
      coords && typeof coords.lat === "number" && typeof coords.lng === "number"
    );
  };

  const defaultCenter = isValidCoordinates(displayCord)
    ? displayCord
    : { lat: -35.3081, lng: 149.1244 };

  useEffect(() => {
    HandleClientChange();
  }, [clientId]);
  const [ansData, setAnsData] = useState([]);
  const HandleClientChange = () => {
    axios
      .get(`${getClientRiskAssesment}/${clientId}`)
      .then((res) => {
        setAnsData(res?.data?.data[0]?.riskAssessment);
      })
      .catch((err) => {
        setAnsData([]);
        console.log(err);
      });
  };
  return (
    <>
      <Box id="view-details" className=" client-module-view-details">
        <Box>
          {pathValue !== "insideLogin" ? (
            <>
              <WebHeader />
              <Box mb={5}>{/* <h3>View Details</h3> */}</Box>
            </>
          ) : (
            ""
          )}

          <Box className="view-details-main-box webContainer">
            {pathValue !== "insideLogin" ? (
              <>
                <Grid
                  mb={3}
                  container
                  className="d-flex padding-left-20 padding-right-20 header-topsection"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box id="breadcrumb" className="ps-0">
                      <Link to="/pickup-shifts">
                        <ArrowBackIosIcon />
                      </Link>
                      <Box className="breadcrumb-inr">
                        <h4 className="d-flex align-items-center ">
                          {" "}
                          View Details{" "}
                        </h4>
                        <Box className="breadcrumbs">
                          <Link className="breadcrumbs-link">
                            {" "}
                            View Details{" "}
                          </Link>{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Box className="view-details-pickupshift">
              <Box className="d-flex align-items-start">
                <Box className="view-detail-profile-image">
                  <Avatar aria-label="recipe" className="explore-icon">
                    {userTypeData?.userType !== "serviceProvider" ? (
                      <img
                        src={
                          clientUserData?.image?.location
                            ? clientUserData?.image?.location
                            : noImage
                        }
                        alt="explore.png"
                      />
                    ) : (
                      <img
                        src={
                          sPData?.companyDetails?.logoPath?.location
                            ? sPData?.companyDetails?.logoPath?.location
                            : noImage
                        }
                        alt="explore.png"
                      />
                    )}
                  </Avatar>
                </Box>
                <Box className="view-detail-profile-content">
                  <Box className="d-flex align-items-center ">
                    {userTypeData?.userType !== "serviceProvider" ? (
                      <>
                        <Typography className="profile-title">
                          {clientUserData?.title +
                            " " +
                            clientUserData?.firstName +
                            " " +
                            clientUserData?.lastName}
                        </Typography>
                        <>{renderRatingIcons()}</>
                      </>
                    ) : (
                      <>
                        <Typography className="profile-title">
                          {sPData?.companyDetails?.name}
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Box className="view-detail-client-address d-flex align-items-center">
                    <Box className="d-flex align-items-center">
                      <img src={MapImage} />
                      <Typography variant="body2" component="div">
                        {displayAddress}
                      </Typography>
                    </Box>
                    <Box className="d-flex align-items-center" sx={{ ml: 3 }}>
                      <img
                        src={locationData?.image?.location}
                        alt={locationData?.name}
                        title={locationData?.name}
                      />
                      &nbsp;
                      <Typography
                        className="exp-txt"
                        title={
                          locationData !== undefined && locationData !== null
                            ? locationData?.name
                            : "NA"
                        }
                      >
                        {locationData !== undefined && locationData !== null
                          ? locationData?.name
                          : "NA"}
                      </Typography>
                      &nbsp;
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="view-details-divider">
              <Box className="divider" sx={{ mt: 3 }}></Box>
              {/* view details tabs start  */}
            </Box>
            <Box className="view-tap-box">
              <Tabs
                centered
                scrollButtons="auto"
                variant="scrollable"
                className="tab-container border-none myaccounttabs observation obsevarion-tabs-bg"
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                allowScrollButtonsMobile
              >
                {/* {<><p className="tab-text"> List View </p></>} */}
                <Tab
                  className="view-tab"
                  iconPosition="start"
                  label="About"
                  {...a11yProps(0)}
                />

                <Tab
                  mt={2}
                  className="view-tab"
                  iconPosition="start"
                  label="Details"
                  {...a11yProps(1)}
                />

                <Tab
                  mt={2}
                  className="view-tab"
                  iconPosition="start"
                  label="Services"
                  {...a11yProps(2)}
                />
              </Tabs>

              <Box className="view-detail-panel">
                <TabPanel value={tabValue} index={0}>
                  <Grid
                    container
                    spacing={2}
                    className="about-panel-txt"
                    sx={{ mt: 1 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Name:</Typography>
                        <span>{sPData?.companyDetails?.name}</span>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Service Required:</Typography>

                        {/* <Box
                          className="d-flex align-items-center"
                          sx={{ ml: 3 }}
                        > */}
                        <Stack direction="row" sx={{ ml: 3 }}>
                          <img
                            className="provider-view-img"
                            src={locationData?.image?.location}
                            alt={locationData?.name}
                            title={locationData?.name}
                          />
                          &nbsp;
                          <Typography
                            className="exp-txt"
                            title={
                              locationData !== undefined &&
                                locationData !== null
                                ? locationData?.name
                                : "NA"
                            }
                          >
                            {locationData !== undefined && locationData !== null
                              ? locationData?.name
                              : "NA"}
                          </Typography>
                          &nbsp;
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel
                  value={tabValue}
                  index={1}
                  className="view-details-document-panel"
                >
                  <Grid
                    container
                    spacing={2}
                    className="about-panel-txt"
                    sx={{ mt: 1 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Date & Time: </Typography>
                        <Box
                          className="d-flex align-items-center"
                          sx={{ ml: 3 }}
                        >
                          <span>{dateVal}</span>
                          <br />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Location: </Typography>

                        <Stack direction="row" sx={{ ml: 3 }}>
                          <img
                            className="provider-view-img"
                            src={locationData?.image?.location}
                            alt={locationData?.name}
                          />

                          <Typography>
                            {` ${locationData?.name} Address: ${displayAddress}`}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="google-map">
                        <GoogleMap
                          center={defaultCenter}
                          zoom={10}
                          mapContainerStyle={mapStyles}
                          options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                        >
                          {isValidCoordinates(displayCord) && (
                            <Marker position={displayCord} />
                          )}
                        </GoogleMap>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                {Array.isArray(services) && services.length > 0 ? (
          services.map((category) => (
            <div key={category.serviceCategoryId} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img src={pricingIcon} alt="pricing icon" style={{ width: '20px', height: '20px', marginRight : "10px"}} />
              <img
                src={getIconForCategory(category.serviceCategoryName)}
                alt={`${category.serviceCategoryName} icon`}
                style={{ width: '20px', height: '20px' }}
              />
              <div style={{ marginLeft: '10px'}}>
                {category.serviceCategoryName}
              </div>
            </div>
          ))
        ) : (
          <div>No service categories available</div> 
        )}  
         </TabPanel>
              </Box>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ mt: 3, mb: 5 }}
                className="d-flex align-items-center justify-content-center"
              >
                {hcCheckId !== undefined && hcCheckId === userData?._id ? (
                  <></>
                ) : (
                  <Stack direction="row" spacing={2}>
                    {userData?._id !== undefined && userData?._id !== null ? (
                      <>
                        {apptHc?.includes(userData?._id) ? (
                          <Button
                            variant="contained"
                            className="res-iconbtn"
                            onClick={() => {
                              addRequestFunction(ApptId, userId, "reject");
                            }}
                            startIcon={<SendIcon />}
                          >
                            {/* {disableButton === true ? "Processing..." : "Cancel Request"} */}
                            Cancel Request
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="res-iconbtn"
                            startIcon={<SendIcon />}
                            onClick={() => {
                              addRequestFunction(ApptId, userId, "send");
                            }}
                            disabled={disableButton}
                          >
                            {disableButton === true ? "Processing..." : "Send Request"}
                            {/* Send Request */}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          className="res-iconbtn"
                          startIcon={<SendIcon />}
                          onClick={() => {
                            RedirectDirectFunction(ApptId);
                          }}
                        >
                          Request
                        </Button>
                      </>
                    )}

                    {userData?._id !== undefined && userData?._id !== null ? (
                      <Button
                        variant="outlined"
                        className="fav-iconbtn"
                        startIcon={
                          favourite?.includes(ApptId) ? (
                            <FavoriteIcon style={{ color: "#15A07D" }} />
                          ) : (
                            <FavoriteBorderOutlinedIcon />
                          )
                        }
                        onClick={() => {
                          if (favourite?.includes(ApptId)) {
                            addLikeApptFunction(ApptId, userId, "dislike");
                          } else {
                            addLikeApptFunction(ApptId, userId, "like");
                          }
                        }}
                      >
                        {favourite?.includes(ApptId)
                          ? "Remove From Favourites"
                          : "Add To Favourites"}
                      </Button>
                    ) : (
                      <Link to="" className="explore-view-link">
                        <div onClick={() => RedirectDirectFunction(ApptId)}>
                          <Button
                            variant="outlined"
                            className="fav-iconbtn"
                            startIcon={<FavoriteBorderOutlinedIcon />}
                          >
                            Add To Favourites
                          </Button>
                        </div>
                      </Link>
                    )}
                  </Stack>
                )}
              </Grid>
            </Box>
          </Box>

          {pathValue !== "insideLogin" ? (
            <>
              <WebFooter />
            </>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default ViewDetail;
