import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import PlaceIcon from '@mui/icons-material/Place';
import MailIcon from '@mui/icons-material/Mail';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import CallIcon from '@mui/icons-material/Call';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function BasicTextFields() {
    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },]

    const [hidden, setHidden] = useState(false);
    const [visible, setVisible] = useState(false);

    const [value, setValue] = React.useState();

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <Box id='personal-info' className='account-form'>
            <Box><Typography className='step-count'>Step 1/5</Typography></Box>
            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>Personal Details</Typography>
                </Box>

                <Box className='personal-info'>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4} className='pt-0' >
                            <label>First Name</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className='pt-0'>
                            <label>Middle Name</label>
                            <TextField
                                sx={{ mt: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className='pt-0'>
                            <label>Last Name</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className='input pt-0'>
                            <label>Email</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input form-input-icon-bg'
                                type="Email"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className='input-icon' position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className='input pt-0'>
                            <label>Phone</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input form-input-icon-bg'
                                size="small"
                                type="text"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className='input-icon' position="start">
                                            <CallIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className='input pt-0'>
                            <label>Date of Birth</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 2 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} className='input pt-0 clientdropdown'>
                            <label className="label-color-ac">Residential Address</label>
                            {/* <Autocomplete
                                freeSolo
                                options={options}
                                getOptionLabel={(option) => option.description}
                                renderInput={(params) => ( */}
                                    <TextField
                                        // {...params}
                                        name="residentialAddress"
                                        sx={{ mt: 2, mb: 2 }}
                                        className="form-input form-input-icon-bg"
                                        placeholder="Residential Address"
                                        type="text"
                                        size="small"
                                        InputProps={{
                                            // ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment className="input-icon" position="start">
                                                    <PlaceIcon style={{ marginLeft: "7px" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        // value={formik?.values?.residentialAddress}
                                    // onChange={handleResidentialAddressChange}
                                    />
                                {/* )}
                                onInputChange={(event, value) => fetchAddressSuggestions(value)}
                            /> */}
                        </Grid>
                    </Grid>

                    <Box >
                        <FormControlLabel className='form-checkbox show-check' onClick={() => { setVisible(true) }} sx={{ mt: 2 }} control={<Checkbox />} label="Enter Residential Address manually" />
                    </Box>
                    <Grid container spacing={2} sx={{ display: visible ? "visible" : "none", }}>
                        <Grid item xs={12} md={6} sm={6} className='input pt-0'>
                            <label>Address Line 1</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className='input pt-0'>
                            <label>Address Line 2</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className='input pt-0'>
                            <label>City/Town</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        {/* ....... */}
                        <Grid item xs={12} sm={6} md={3} className='input pt-0'>
                            <label>Province/State</label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ mt: 2, mb: 2 }}
                                options={top100Films}
                                renderInput={(params) => <TextField {...params} />}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className='input pt-0'>
                            <label>Country</label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ mt: 2, mb: 2 }}
                                options={top100Films}
                                renderInput={(params) => <TextField {...params} />}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} className='input pt-0'>
                            <label>Pincode</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Box >
                        <FormControlLabel className='form-checkbox' control={<Checkbox />} sx={{ mt: 2 }} label="Postal address is the same as residential address." />
                    </Box>
                </Box>

            </Box>
            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>Professional Details</Typography>
                </Box>
                <Grid className='personal-info' container spacing={2}>
                    <Grid item xs={12} sm={4} className='pt-0' >
                        <label>Job Title</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ mt: 2, mb: 2 }}
                            options={top100Films}
                            renderInput={(params) => <TextField {...params} />}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className='pt-0'>
                        <label>Job Type</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ mt: 2, mb: 2 }}
                            options={top100Films}
                            renderInput={(params) => <TextField {...params} />}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className='pt-0'>
                        <label>Level</label>
                        <Autocomplete
                            disablePortal
                            sx={{ mt: 2, mb: 2 }}
                            id="combo-box-demo"
                            options={top100Films}
                            size="small"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>Bank Details</Typography>
                </Box>
                <Grid className='personal-info' container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} className='pt-0'>
                        <label>Bank Name</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className='pt-0'>
                        <label>Account Holder Name</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className='input pt-0'>
                        <label>Account Number</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className='input pt-0'>
                        <label>BSB</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className='input pt-0'>
                        <label>Tax File Number</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Number"
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>

    );
}