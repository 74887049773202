import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { verifyMailOtp, resendOtp, setIndvHCinAppt } from "../../api";
import { toast } from "react-toastify";
import BannerSlider from "../../components/ui/BannerSlider";
import Link from "@mui/material/Link";
import WebHeader from "../../Website/Web-Header";

export default function SignInSide() {
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonResend, setDisableButtonResend] = useState(false);
  const navigate = useNavigate();
  let exploreMarket = JSON.parse(localStorage.getItem("exploreMarket"));
  let hcId = exploreMarket?.hcId;

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    navigate("/sign-in");
  };

  const location = useLocation();
  //const emailId = location.state;
  const emailId = location?.state?.emailID;
  const pageName = location?.state?.pageName;
  const selectedUserId = location?.state?.selectedUserId;
  const apptId = location?.state?.apptId;
  console.log(" location: ", location?.state);
  const initialValues = {
    emailOTP: "",
  };
  const validationSchema = Yup.object().shape({
    emailOTP: Yup.string().required("OTP is required"),
  });

  const ResendOtpCode = () => {
    const postData = {
      email: emailId,
    };
    setDisableButtonResend(true);
    axios
      .post(resendOtp, postData)
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          setDisableButtonResend(false);
        } else {
          setDisableButtonResend(false);
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Please try again, Something went wrong!");
      });
  };

  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    ResendOtpCodeTimer();
    if (seconds === 0) {
      setDisableButtonResend(false);
    }
  }, [seconds]);

  // useEffect(() => {
  const ResendOtpCodeTimer = () => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(29);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  };
  //}, [seconds]);
  return (
    <Box id="personal-info" width="100%" height="100%">
      <Box>
        <WebHeader />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Box className="form-main-box">
              <Box>
                <h2 mb={3} className="sign-title">
                  Two Factor Authentication
                </h2>
              </Box>
              <Box>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikHelpers) => {
                    Object.assign(values, { email: emailId });
                    setDisableButton(true);
                    axios
                      .post(verifyMailOtp, values)
                      .then((res) => {
                        if (res.data.success === false) {
                          setDisableButton(false);
                          toast.error(res.data.message);
                        }
                        if (res.data.success === true) {
                          setDisableButton(false);

                          let data = res.data.userData;
                          data["token"] = res.data.token;
                          data["refreshToken"] = res.data.refreshToken;
                          const userData = res.data.userData;

                          // Convert the object to a string before storing it in the cookie
                          // Cookies.set("nameUserdata7678", userData.email);
                          localStorage.setItem(
                            "userData",
                            JSON.stringify(data)
                          );

                          toast.success(res.data.message);

                          let roleData = res.data.userData.role;
                          let userStatusData = res.data.userData.userStatus;
                          let UserType = res.data.userData.createdByUserType;

                          console.log(
                            "location?.state--------",
                            location?.state,
                            userData
                          );

                          if (
                            userData?.isSoleTrader === true &&
                            location?.state?.request?.types == "findSupport"
                          ) {
                            axios
                              .put(
                                `${setIndvHCinAppt}/${location?.state?.request?._id}/${userData?._id}/send/findSupport/${location?.state?.request?.clientId}`,
                                {
                                  date: location?.state?.request?.date,
                                  startTime:
                                    location?.state?.request?.startTime,
                                  endTime: location?.state?.request?.endTime,
                                  shiftName:
                                    location?.state?.request?.shiftName,
                                }
                              )
                              .then((res) => {
                                // toast.success(res.data.message);
                                // setTimeout(() => {
                                //   navigate("/health-carer/appointment-connection-list");
                                // }, 500);
                              })
                              .catch((error) => {
                                // toast.error("Something went wrong");
                              });
                          } else {
                            // toast.error("Only ABN Holders Can Send Request");
                          }

                          if (roleData === "healthCarer") {
                            if (userStatusData === "Approved") {
                              if (res?.data?.userData?.packageMenuId) {
                                if (pageName === "hcLogin") {
                                  window.location.href =
                                    "/health-carer/appointment-connection-list";
                                } else {
                                  window.location.href = "/health-carer/home";
                                }
                              } else {
                                if (UserType === "superAdmin") {
                                  window.location.href =
                                    "/health-carer/list-package-menu";
                                } else {
                                  window.location.href = "/health-carer/home";
                                }
                              }
                            } else if (userStatusData === "Rejected") {
                              window.location.href =
                                "/health-carer/pending-approval";
                            } else {
                              if (res.data.userData.activeStep === 4) {
                                window.location.href =
                                  "/health-carer/pending-approval";
                              } else {
                                window.location.href = "/health-carer/onboard";
                              }
                            }
                          } else if (roleData === "supportCoordinator") {
                            if (userStatusData === "Approved") {
                              if (res?.data?.userData?.packageMenuId) {
                                window.location.href =
                                  "/support-coordinator/home";
                              } else {
                                if (UserType === "superAdmin") {
                                  window.location.href =
                                    "/support-coordinator/list-package-menu";
                                } else {
                                  window.location.href =
                                    "/support-coordinator/home";
                                }
                              }
                            } else if (userStatusData === "Rejected") {
                              window.location.href =
                                "/support-coordinator/pending-approval";
                            } else {
                              if (res.data.userData.activeStep === 4) {
                                window.location.href =
                                  "/support-coordinator/pending-approval";
                              } else {
                                window.location.href =
                                  "/support-coordinator/onboard";
                              }
                            }
                          } else if (roleData === "serviceProvider") {
                            if (userStatusData === "Approved") {
                              if (res?.data?.userData?.packageMenuId) {
                                //  window.location.href = "/service-provider/home";
                                if (pageName === "existingUser") {
                                  window.location.href =
                                    "/service-provider/view-details";
                                } else {
                                  window.location.href =
                                    "/service-provider/home";
                                }
                              } else {
                                if (UserType === "superAdmin") {
                                  window.location.href =
                                    "/service-provider/list-package-menu";
                                } else {
                                  window.location.href =
                                    "/service-provider/home";
                                }
                              }
                            } else if (userStatusData === "Rejected") {
                              window.location.href =
                                "/service-provider/pending-approval";
                            } else {
                              if (res.data.userData.activeStep === 1) {
                                window.location.href =
                                  "/service-provider/pending-approval";
                              } else {
                                window.location.href =
                                  "/service-provider/onboard";
                              }
                            }
                          }

                          // else if (
                          //   roleData === "client" &&
                          //   hcId !== undefined &&
                          //   hcId !== null
                          // ) {
                          //   window.location.href = "/client/view-details";
                          // }
                          else if (roleData === "client") {
                            if (userStatusData === "Approved") {
                              if (res?.data?.userData?.packageMenuId) {
                                // window.location.href = "/client/home";

                                if (pageName === "existingUser") {
                                  window.location.href = "/client/view-details";
                                } else {
                                  window.location.href = "/client/home";
                                }
                              } else {
                                if (UserType === "superAdmin") {
                                  window.location.href =
                                    "/client/list-package-menu";
                                } else {
                                  window.location.href = "/client/home";
                                }
                              }
                            } else if (userStatusData === "Rejected") {
                              window.location.href = "/client/pending-approval";
                            } else {
                              if (res.data.userData.activeStep === 4) {
                                window.location.href =
                                  // "/client/pending-approval";
                                  window.location.href = "/client/home";
                              } else {
                                window.location.href = "/client/onboard";
                              }
                            }
                          } else if (roleData === "superAdmin") {
                            window.location.href = "/super-admin/home";
                          } else if (roleData === "Stakeholder") {
                            // window.location.href = "/stakeholder/home";

                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/stakeholder/pending-approval";
                            } else {
                              window.location.href = "/stakeholder/home";
                            }
                          } else if (roleData === "itManager") {
                            // window.location.href = "/it-manager/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/it-manager/pending-approval";
                            } else {
                              window.location.href = "/it-manager/home";
                            }
                          } else if (roleData === "planManager") {
                            //  window.location.href = "/plan-manager/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/plan-manager/pending-approval";
                            } else {
                              window.location.href = "/plan-manager/home";
                            }
                          } else if (roleData === "supervisor") {
                            // window.location.href = "/supervisor/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/supervisor/pending-approval";
                            } else {
                              window.location.href = "/supervisor/home";
                            }
                          } else if (roleData === "accountant") {
                            // window.location.href = "/accountant/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/accountant/pending-approval";
                            } else {
                              window.location.href = "/accountant/home";
                            }
                          } else if (roleData === "familyMember") {
                            // window.location.href = "/family-member/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/family-member/pending-approval";
                            } else {
                              window.location.href = "/family-member/home";
                            }
                          } else if (roleData === "generalManager") {
                            // window.location.href = "/general-manager/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href =
                                "/general-manager/pending-approval";
                            } else {
                              window.location.href = "/general-manager/home";
                            }
                          } else if (roleData === "hr") {
                            // window.location.href = "/hr/home";
                            if (
                              userStatusData === "Rejected" ||
                              userStatusData === "Pending"
                            ) {
                              window.location.href = "/hr/pending-approval";
                            } else {
                              window.location.href = "/hr/home";
                            }
                          } else {
                            const roleDataLowerCase = roleData.toLowerCase();
                            window.location.href = `/${roleDataLowerCase}/home`;
                          }
                        }

                        //here testing

                        // if (res.data.success === true) {
                        //   console.log("res.data.userData", res.data.userData);
                        //   console.log("res.data.userData.activeStep", res.data.userData.activeStep);
                        // }
                      })
                      .catch((error) => {
                        toast.error("Something went wrong");
                      });
                  }}
                >
                  {({ errors, touched, isValid, dirty }) => (
                    <Form>
                      <span> Email : {emailId}</span>
                      {/* <label>Enter OTP </label> */}
                      <Field
                        size="small"
                        name="emailOTP"
                        type="password"
                        as={TextField}
                        className="form-input"
                        margin="normal"
                        fullWidth
                        id="outlined-required"
                        placeholder="Enter OTP"
                        error={
                          Boolean(errors.emailOTP) && Boolean(touched.emailOTP)
                        }
                        helperText={
                          Boolean(touched.emailOTP) && errors.emailOTP
                        }
                      />
                      <Button
                        className="signupbutton"
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disabled={disableButton}
                      >
                        {disableButton === true
                          ? "Processing..."
                          : "Submit OTP"}
                      </Button>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {
                          <>
                            <Typography color="#929495">
                              Not received your OTP?{" "}
                              {disableButtonResend === true ? (
                                <>
                                  {/* {seconds > 0 || minutes > 0 ? (
                                    <p>
                                      {minutes < 10 ? `0${minutes}` : minutes}:
                                      {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                  ) : (
                                    ""
                                  )} */}
                                  {"Processing..."}
                                </>
                              ) : (
                                <>
                                  <Link
                                    style={{ cursor: "pointer" }}
                                    onClick={ResendOtpCode}
                                    variant="body2"
                                  >
                                    {"Resend OTP"}
                                  </Link>
                                </>
                              )}
                            </Typography>
                          </>
                        }
                      </Box>

                      {/* <div className="countdown-text">
                        {seconds > 0 || minutes > 0 ? (
                          <p>
                            Time Remaining:{" "}
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <p>Didn't recieve code?</p>
                        )}

                        <button
                          disabled={seconds > 0 || minutes > 0}
                          style={{
                            color:
                              seconds > 0 || minutes > 0
                                ? "#DFE3E8"
                                : "#FF5630",
                          }}
                          onClick={ResendOtpCode}
                        >
                          Resend OTP
                        </button>
                      </div> */}

                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {
                          <>
                            <Typography color="#929495">
                              Back to{" "}
                              <Link
                                onClick={fieldLogout}
                                variant="body2"
                                style={{ cursor: "pointer" }}
                              >
                                Login
                              </Link>
                            </Typography>
                          </>
                        }
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
