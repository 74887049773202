/************************************
1. If you want to add or remove items you will need to change a variable called $slide-count in the CSS *minimum 3 slides

2. if you want to change the dimensions of the slides you will need to edit the slideWidth variable here 👇 and the $slide-width variable in the CSS.
************************************/

import React, { useEffect } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import FilterStore from "../store/FilterStore";
import FilterStore from "../../availability/store/FilterStore";
const slideWidth = 30;

const _items = [
  {
    player: {
      title: "January",
    },
  },
  {
    player: {
      title: "February",
    },
  },
  {
    player: {
      title: "March",
    },
  },
  {
    player: {
      title: "April",
    },
  },
  {
    player: {
      title: "May",
    },
  },
  {
    player: {
      title: "June",
    },
  },
  {
    player: {
      title: "July",
    },
  },
  {
    player: {
      title: "August",
    },
  },
  {
    player: {
      title: "September",
    },
  },
  {
    player: {
      title: "October",
    },
  },
  {
    player: {
      title: "November",
    },
  },
  {
    player: {
      title: "December",
    },
  },
];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// const createItem = (position, idx) => {
//   const item = {
//     styles: {
//       transform: `translateX(${position * slideWidth}rem)`,
//     },
//     player: _items[idx].player,
//   };

//   switch (position) {
//     case length - 1:
//     case length + 1:
//       break;
//     case length:
//       break;
//     default:
//       break;
//   }

//   return item;
// };
const createItem = (position, idx, activeIdx) => {
  const actualIndex = (activeIdx + position + length) % length; // Calculate actual index
  const item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    player: _items[actualIndex].player, // Use actual index to get correct month
  };

  return item;
};

// const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
//   const item = createItem(pos, idx, activeIdx);

//   return (
//     <li className="carousel__slide-item" style={item.styles}>
//       <div className="carousel__slide-item-img-link">
//         <label for="month" className="calender-slide-input-lable">
//           {item.player.title}
//         </label>
//         <input type="radio" value="month" className="calender-slide-input" />
//       </div>
//     </li>
//   );
// };
const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
  const item = createItem(pos - activeIdx, idx, activeIdx); // Adjust position relative to activeIdx

  return (
    <li className="carousel__slide-item" style={item.styles}>
      <div className="carousel__slide-item-img-link">
        <label htmlFor="month" className="calender-slide-input-lable">
          {item.player.title}
        </label>
        <input type="radio" value="month" className="calender-slide-input" />
      </div>
    </li>
  );
};

const keys = Array.from(Array(_items.length).keys());

const Carousel = () => {
  const { resetFields, setField, month, activeMonthIndex, isReloadMonth } =
    FilterStore();
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;
  // console.log("check123 activeMonthIndex", activeMonthIndex);
  // console.log("check123 activeIdx", activeIdx);
  // console.log("check123 isReloadMonth", isReloadMonth);

  // const prevClick = (jump = 1) => {
  //   if (!isTicking) {
  //     setIsTicking(true);
  //     setItems((prev) => {
  //       return prev.map((_, i) => prev[(i + jump) % bigLength]);
  //     });
  //   }
  // };

  // const nextClick = (jump = 1) => {
  //   if (!isTicking) {
  //     setIsTicking(true);
  //     setItems((prev) => {
  //       return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
  //     });
  //   }
  // };
  // console.log("check items", items);
  // console.log("check bigLength", items);

  const prevClick = () => {
    if (!isTicking) {
      setIsTicking(true);
      setActiveIdx((prev) => {
        const newIndex = (prev - 1 + length) % length;
        setItems((prevItems) => {
          // Rotate items to the right
          const rotatedItems = [
            prevItems[prevItems.length - 1],
            ...prevItems.slice(0, prevItems.length - 1),
          ];
          return rotatedItems;
        });
        return newIndex;
      });
    }
  };

  const nextClick = () => {
    if (!isTicking) {
      setIsTicking(true);
      setActiveIdx((prev) => {
        const newIndex = (prev + 1) % length;
        setItems((prevItems) => {
          // Rotate items to the left
          const rotatedItems = [...prevItems.slice(1), prevItems[0]];
          return rotatedItems;
        });
        return newIndex;
      });
    }
  };

  const handleDotClick = (idx) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  // React.useEffect(() => {
  //   setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  //   setField(
  //     "month",
  //     _items[(length - (items[0] % length)) % length]?.player?.title
  //   );
  // }, [items]);
  // React.useEffect(() => {
  //   setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  //   setField(
  //     "month",
  //     _items[(length - (items[0] % length)) % length]?.player?.title
  //   );
  // }, [items]);

  React.useEffect(() => {
    setField("month", _items[activeIdx]?.player?.title);
  }, [activeIdx]);

  React.useEffect(() => {
    if (!isTicking) {
      // Get the current month index
      const currentMonthIndex = new Date().getMonth();

      // Set activeIdx and month field directly
      setActiveIdx(currentMonthIndex);
      setField("month", _items[currentMonthIndex]?.player?.title);
    }
  }, []);

  useEffect(() => {
    if (isReloadMonth) {
      setActiveIdx(activeMonthIndex);
      setField("isReloadMonth", false);
    }
  }, [isReloadMonth]);

  // console.log("check activeIdx", activeIdx);
  // console.log("check items", items);

  return (
    <div id="calender-filter-slide">
      <div className="carousel__wrap">
        <div className="carousel__inner select-slot-calender">
          <button
            className="carousel__btn carousel__btn--prev"
            onClick={() => prevClick()}
          >
            <ArrowLeftIcon />
          </button>
          <div className="carousel__container">
            <ul className="carousel__slide-list">
              {items.map((pos, i) => (
                <>
                  {" "}
                  <CarouselSlideItem
                    key={i}
                    idx={i}
                    pos={pos}
                    activeIdx={activeIdx}
                  />
                </>
              ))}
            </ul>
          </div>
          <button
            className="carousel__btn carousel__btn--next"
            onClick={() => nextClick()}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
