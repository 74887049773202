import { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { setAuthToken } from "../../../../src/helpers/apiCallHelper";

import {
  listSidebarMenuByRoleID,
  getuserDetail,
  getServiceProviderActivePackage,
  accessRoleListScSp,
  checkDataPresentOrNot,
  accessRoleList,
} from "../../../api";
import refreshAccessToken from "../../../helpers/refreshTokanHelper";
import axios from "axios";
import localstorageData from "../../../helpers/localstorageData";
import { getCreatedBy } from "../../../helpers/commonHelper";
import { Link, useNavigate } from "react-router-dom";
import zustandStore from "../../../helpers/zustandStore";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function AdminUserRoutes(props) {
  const navigate = useNavigate();

  const menusLocal = JSON.parse(localStorage.getItem("selectedMenus"));
  const initialMenu =
    menusLocal === null || menusLocal === undefined || menusLocal === ""
      ? {}
      : menusLocal;
  const user = localstorageData();
  let createdBy = getCreatedBy();

  const [menuListData, setMenuList] = useState([]);
  const [clientTabconditionalList, setClientTabconditionalList] = useState([]);
  const [subMenuToggle, setSubMenuToggle] = useState(initialMenu);
  const [subMenuToggleActive, setSubMenuToggleActive] = useState({});

  const [isIndividual, setIsIndividual] = useState(false);
  const [isDuringEvaluation, setIsDuringEvaluation] = useState(false);
  const [menuList, setmenuList] = useState([]);
  const { resetFields, setField, menuListCheck } = zustandStore();

  useEffect(() => {
    // getUserList();
    // getServiceProviderRouteAccess();
  }, []);

  useEffect(() => {
    if (user.role !== undefined) {
      if (
        user.role === "serviceProvider" ||
        user.role === "client" ||
        user.role === "healthCarer" ||
        user.role === "supportCoordinator"
      ) {
        getServiceProviderActivePackageData();
        getServiceProviderRouteAccess();
      } else {
        getUserList();
        getServiceProviderRouteAccess();
      }
    }
  }, []);

  const getServiceProviderActivePackageData = () => {
    axios
      .get(
        `${getServiceProviderActivePackage}?id=${user?._id}&userTypeId=${user.userTypeId}&role=${user?.role}&child=${user?.createdByUserType}`
      )
      .then(function (res) {
        if (res) {
          setMenuList(res.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.remainDays !== undefined && props.remainDays <= 0) {
      if (user.role === "client") {
        setField("menuListCheck", [
          "/client/list-package-menu",
          "/client/myaccount",
        ]);
        setmenuList(["/client/list-package-menu", "/client/myaccount"]);
        navigate("/client/list-package-menu");
      } else if (user.role === "healthCarer") {
        setField("menuListCheck", [
          "/health-carer/interview-list",
          "/health-carer/list-package-menu",
          "/health-carer/subscription-plans-list",
          "/health-carer/myaccount",
        ]);
        setmenuList([
          "/health-carer/interview-list",
          "/health-carer/list-package-menu",
          "/health-carer/subscription-plans-list",
          "/health-carer/myaccount",
        ]);
        // navigate('/health-carer/list-package-menu')
      }
    }
  }, [props.remainDays]);

  const getServiceProviderRouteAccess = () => {
    if (user.createdByUserType === "serviceProvider") {
      createdBy = user.createdBy;
    } else if (user.createdByUserType === "supportCoordinator") {
      createdBy = user.createdBy;
    }

    axios
      .get(
        `${accessRoleList}?roleId=${
          user.userTypeId
        }&createdBy=${createdBy}&role=${user.role}&userId=${
          user._id
        }&userType=${user?.createdByUserType}&child=${
          user?.createdByUserType
        }&isABNHolder=${user?.isSoleTrader}&isABNHolderHc=${
          user?.isSoleTrader
        }&bookAdemo=${user?.isBookDemoUser}&isSc=${
          user?.isSoleTrader
        }&evlClient=${
          user?.packageMenuId == false && user?.role == "client"
            ? "true"
            : "false"
        }`
      )
      .then(function (res) {
        if (res) {
          if (!(res.data.success == true)) {
            if (refreshAccessToken()) getServiceProviderRouteAccess();
          }
          setField("menuListCheck", res.data.data);
          setmenuList(res.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUserList = async () => {
    localStorage.setItem("selectedMenus", JSON.stringify({}));

    try {
      setAuthToken(); // Set the authorization header with the access token
      const res = await axios
        .get(`${listSidebarMenuByRoleID}/${user.role}`)
        .then((res) => {
          setMenuList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function isPathInRoutes(path) {
    if (user.role === "serviceProvider") {
      return menuList.includes(path);
    } else if (user.role === "admin") {
      return menuList.includes(path);
    } else if (user.role === "supportCoordinator") {
      return menuList.includes(path);
    } else if (user.role === "healthCarer") {
      return menuList.includes(path);
    } else if (user.role === "client") {
      // if (isDuringEvaluation) {
      //   return true;
      // } else {
      return menuList.includes(path);
      // }
    } else {
      return menuList.includes(path);
    }
  }

  const subMenuClick = (subMenuId) => {
    setSubMenuToggle((prevTaskShow) => ({
      [subMenuId]: !prevTaskShow[subMenuId],
    }));

    localStorage.setItem(
      "selectedMenus",
      JSON.stringify({ [subMenuId]: !subMenuToggle[subMenuId] })
    );
  };

  const subMenuClick2 = (subMenuId, subId) => {
    localStorage.setItem(
      "selectedMenus",
      JSON.stringify({ [subMenuId]: true })
    );

    setSubMenuToggleActive((prevTaskShow) => ({
      [subId]: !prevTaskShow[subId],
    }));
  };

  const clientTabDuringEvaluation = [
    "Dashboard",
    "Meeting List",
    "Medical History",
    "Funding",
    "Risk Assesment",
    "Goals",
    "Emergency Contact",
  ];

  const clientTabAfterEvaluation = [
    "Dashboard",
    "Appointment List",
    "Notes",
    "Tasks",
    "Goals",
    "Routine & Tasks",
    "Progress Notes",
    "Medical History",
    "Funding",
    "Risk Assesment",
    "Community Access Provider",
    "Complaint / Feedback Management",
    "Conflict of Interest Register",
    "Emergency Contact",
    "Invoices",
    "Plan Manager",
    "Reports",
    "Search Support",
    "Incident",
  ];

  let clientTabconditional;

  useEffect(() => {
    if (user.role === "supportCoordinator") {
      axios
        .get(`${getuserDetail}/${user._id}`)
        .then(function (res) {
          if (res) {
            setIsIndividual(res?.data?.data?.userData?.isIndividual);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (user.role === "client") {
      axios
        .get(`${getuserDetail}/${user._id}`)
        .then(function (res) {
          if (res) {
            let clientActiveStep = res?.data?.data?.activeStep;
            if (
              clientActiveStep === 4 &&
              res?.data?.data?.userStatus === "Approved"
            ) {
              clientTabconditional = clientTabAfterEvaluation;
            } else {
              clientTabconditional = clientTabDuringEvaluation;
              setIsDuringEvaluation(true);
            }
            setClientTabconditionalList(clientTabconditional);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [user._id !== undefined]);
  // function for route change
  const getRoute = (path) => {
    if (user.role === "serviceProvider") {
      return path.replace("/super-admin", "/service-provider");
    } else if (user.role === "admin") {
      return path.replace("/super-admin", "/admin");
    } else {
      return path;
    }
  };

  const checkDataPresentOrNotFn = (e, name) => {
    if (name === "Add Appointment") {
      e.preventDefault();
      axios
        .get(`${checkDataPresentOrNot}?createdBy=${createdBy}`)
        .then(async (res) => {
          let msg = res?.data?.message;

          const state = {
            healthCarerId: "",
            editId: "",
            back: "Add New",
          };

          if (msg === "" || msg === null) {
            if (user?.role === "serviceProvider") {
              // navigate("/service-provider/add-appointment", { state });
              window.location.href = "/service-provider/add-appointment";
            } else {
              // navigate("/super-admin/add-appointment", { state });
              window.location.href = "/super-admin/add-appointment";
            }
          } else {
            // toast.error(msg);
            Swal.fire("Error!", msg, "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <List
        className="sidebar-item"
        component="nav"
        id="sidebar-menu"
        aria-labelledby="nested-list-subheader"
      >
        {console.log("wqdwdwedqwdewqe1111222222")}
        {menuListData.map((val, index) => {
          if (
            val?.mainMenu?.name === "Package Menu" ||
            val?.mainMenu?.name === "Subscription Plans" ||
            val?.mainMenu?.name === "Settings"
          ) {
            return;
          }

          const data = val?.subMenu?.filter((obj) => obj.visiblity == false);
          return (
            <>
              {!val.isSubMenu || data.length == val?.subMenu?.length ? (
                <>
                  {console.log("val?.subMenu", val?.subMenu)}
                  {val?.mainMenu?.name !== undefined &&
                  val?.mainMenu?.name !== null &&
                  isPathInRoutes(val?.mainMenu?.url) ? (
                    // && (isPathInRoutes(val?.mainMenu?.url) || !user?.isBookDemoUser)
                    // <>{val?.mainMenu?.name == "Calendar" && user?.role !== "client" && user?.role !== "Stakeholder" && user?.role !== "familyMember" && user?.role !== "serviceProvider" && user?.role !== "supportCoordinator" && user?.role !== "healthCarer" ?

                    <Link
                      to={
                        isPathInRoutes(val?.mainMenu?.url)
                          ? getRoute(val?.mainMenu?.url)
                          : "#"
                      }
                      className={
                        isPathInRoutes(val?.mainMenu?.url)
                          ? `sidebar-item-link`
                          : `agency-add-new sidebar-item-link` +
                            val?.mainMenu?.url
                      }
                      key={"link" + index}
                      title={
                        isPathInRoutes(val?.mainMenu?.url)
                          ? ""
                          : "Unauthorized Access"
                      }
                    >
                      <ListItemIcon
                        className="sidebar-item-icon"
                        key={"link2" + index}
                      >
                        <img src={val?.mainMenu?.icon?.location}></img>
                      </ListItemIcon>
                      <ListItemText
                        className="menu-link-hide"
                        primary={val?.mainMenu?.name}
                        key={"link3" + index}
                      />
                    </Link>
                  ) : (
                    //       : <>{val?.mainMenu?.name == "Calendar" ? "" : <Link
                    //         to={
                    //           isPathInRoutes(val?.mainMenu?.url)
                    //             ? getRoute(val?.mainMenu?.url)
                    //             : "#"
                    //         }
                    //         className={
                    //           isPathInRoutes(val?.mainMenu?.url)
                    //             ? `sidebar-item-link`
                    //             : `agency-add-new sidebar-item-link` +
                    //             val?.mainMenu?.url
                    //         }

                    //         key={"link" + index}

                    //         title={
                    //           isPathInRoutes(val?.mainMenu?.url)
                    //             ? ""
                    //             : "Unauthorized Access"
                    //         }
                    //       >
                    //         <ListItemIcon
                    //           className="sidebar-item-icon"
                    //           key={"link2" + index}
                    //         >
                    //           <img src={val?.mainMenu?.icon?.location}></img>
                    //         </ListItemIcon>
                    //         <ListItemText
                    //           className="menu-link-hide"
                    //           primary={val?.mainMenu?.name}
                    //           key={"link3" + index}
                    //         />
                    //       </Link>}</>}</>

                    ""
                  )}
                </>
              ) : (
                <>
                  {val?.mainMenu?.name !== undefined &&
                  val?.mainMenu?.name !== null &&
                  menuList.length > 0 &&
                  menuList?.some((url) =>
                    val?.subMenu.some((menuItem) => menuItem.url === url)
                  ) ? (
                    <>
                      <Link
                        className="sidebar-item-link  "
                        // className={
                        //   isPathInRoutesArr(val?.subMenu)
                        //     ? `sidebar-submenu-link`
                        //     : "agency-add-new sidebar-submenu-link "
                        // }
                        onClick={() => subMenuClick(val._id)}
                        key={"links1" + index}
                      >
                        <ListItemIcon
                          className="sidebar-item-icon"
                          key={"links2" + index}
                        >
                          {/* <ManageHistoryIcon /> */}
                          <img src={val?.mainMenu?.icon?.location}></img>
                        </ListItemIcon>
                        <ListItemText
                          className="menu-link-hide"
                          primary={val.mainMenu.name}
                        />
                        {subMenuToggle[val._id] ? (
                          <ExpandLess className="expand-icon" />
                        ) : (
                          <ExpandMore className="expand-icon" />
                        )}
                      </Link>
                      <Collapse
                        in={subMenuToggle[val._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {val.subMenu.map((subMenuItem, No) => {
                            const st =
                              subMenuToggleActive[subMenuItem._id] == true
                                ? "active"
                                : "";
                            const validateSubMenu = subMenuItem?.url;
                            const checkvisibal = subMenuItem?.visiblity;
                            // const isSupportCoordinator = subMenuItem[user?.role] === true;
                            return (
                              <>
                                {isPathInRoutes(validateSubMenu) &&
                                  checkvisibal && (
                                    <Link
                                      to={
                                        isPathInRoutes(subMenuItem?.url)
                                          ? getRoute(subMenuItem?.url)
                                          : "#"
                                      }
                                      // to={getRoute(subMenuItem?.url)}
                                      sx={{ pl: 4 }}
                                      // className={`sidebar-submenu-link ${st}`}
                                      className={
                                        isPathInRoutes(subMenuItem?.url)
                                          ? `sidebar-submenu-link ${st}`
                                          : "agency-add-new sidebar-submenu-link "
                                      }
                                      // className={`sidebar-submenu-link ${st}`}
                                      title={""}
                                      key={"Item" + index + No}
                                      onClick={(e) => {
                                        subMenuClick2(val._id, subMenuItem._id);
                                        checkDataPresentOrNotFn(
                                          e,
                                          subMenuItem.name
                                        );
                                      }}
                                    >
                                      <ListItemText
                                        primary={subMenuItem.name}
                                      />
                                    </Link>
                                  )}
                              </>
                            );
                          })}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          );
        })}
      </List>
    </>
  );
}
