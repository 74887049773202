import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import { getUserRolePackageMenu, createdBy } from "../../api";
import pricingIcon from "../../assets/images/pricing-tick-icon.png";
import standardImg from "../../assets/images/pricing-card-topchip.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import axios from "../../api/axios";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PricingTabs() {
  const [value, setValue] = useState(0);
  const [listData, setListData] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState('Service%20Provider');

  useEffect(() => {
    getaList();
  }, [selectedLabel]);

  const getaList = async () => {
    axios
      .get(
        `${getUserRolePackageMenu}?page=1&createdBy=${createdBy}&usertype=${selectedLabel}`
      )
      .then((res) => {
        setListData(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const clickedLabel = event.target.innerText;
    setSelectedLabel(clickedLabel);

  };

  return (
    <Box id="PricingTab" sx={{ width: "100%" }} className="position-relative">
      <Grid container>
        <Grid item xs={12} sm={10} md={10} className="m-auto">
          <Box className="home-tab-wrapper pt-2 userWidth" >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example pt-2"

            >
              <Tab
                className="hc-appointment-tabLable find margin"
                label="Service Provider"
                {...a11yProps(0)}
              />
              <Tab
                className="hc-appointment-tabLable find margin"
                label="Support Coordinator"
                {...a11yProps(1)}
              />
              <Tab
                className="hc-appointment-tabLable find margin"
                label="Health Carer"
                {...a11yProps(2)}
              />
              <Tab
                className="hc-appointment-tabLable find margin"
                label="Client"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
      <Box className="space-pt-60">
  <CustomTabPanel>
    <Box className="Pricing-Card-main">
      <Grid container spacing={2} className="flexWrap">
        {listData?.length !== 0 ? (
          listData.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex' }}>
              <Box className="pricing-card" style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '400px' }}>
                <Box className="text-center space-mb-24">
                  <Typography>{item?.packageName}</Typography>

                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Typography className="menu-cardsubheader" component="div">
                      <h1> {"$" + item?.price}</h1>
                    </Typography>

                    <Typography className="pricingcard-non-sub-month" component="div">
                      {" "} / Month{" "}
                    </Typography>
                  </Stack>
                  <Typography className="menu-cardsubheader" component="div">
                    {" "}{item?.duration + " Days"}{" "}
                  </Typography>
                </Box>

                <Box className="pricing-pointss" style={{ flexGrow: 1, overflowY: 'auto', paddingBottom: '20px' }}>
                  {item.menuDetails?.length !== 0
                    ? item.menuDetails.map((x, ii) => (
                        <Box key={ii} style={{ padding: "5px" }}>
                          <Stack direction="row" spacing={2}>
                            <CheckCircleIcon className="right-tick" />
                            {x}
                          </Stack>
                        </Box>
                      ))
                    : ""}
                </Box>

                <Box className="text-center d-flex justify-content-center" style={{ marginTop: 'auto', padding: '10px 0' }}>
                  <Button component="a" href="/sign-in" className="pricing-card-btn">
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item>No data available.</Grid>
        )}
      </Grid>
    </Box>
  </CustomTabPanel>
</Box>
    </Box>
  );
}
