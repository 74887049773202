import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import MailIcon from '@mui/icons-material/Mail';
import InputAdornment from '@mui/material/InputAdornment';
import CallIcon from '@mui/icons-material/Call';

export default function BasicTextFields() {

    return (
        <Box id='personal-info'>

            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>References</Typography>
                </Box>

                <Grid className='personal-info' container spacing={2}>

                    <Grid item xs={12}>
                        <Typography className='references'>1st Reference:</Typography>
                    </Grid>


                    <Grid item xs={12} md={6} sm={6} className='pt-0'>
                        <label>Full Name</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} className='pt-0'>
                        <label>Company</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className='input pt-0' >
                        <label>Email</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input form-input-icon-bg'
                            type="Email"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment className='input-icon' position="start">
                                        <MailIcon />
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className='input pt-0'>
                        <label>Phone</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input form-input-icon-bg'
                            size="small"
                            type="text"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment className='input-icon' position="start">
                                        <CallIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>



                </Grid>

                <Grid className='personal-info' container spacing={2}> 

                    <Grid item xs={12}>
                        <Typography className='references'>2st Reference:</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} className='pt-0'>
                        <label>Full Name</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} className='pt-0'>
                        <label>Company</label>
                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            className='form-input'
                            type="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}  className='input pt-0'>
                            <label>Email</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input form-input-icon-bg'
                                type="Email"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment className='input-icon' position="start">
                                        <MailIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                 
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}  className='input pt-0'>
                            <label>Phone</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input form-input-icon-bg'
                                size="small"
                                type="text"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment className='input-icon' position="start">
                                        <CallIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                            />
                        </Grid>



                </Grid>

            </Box>

        </Box>

    );
}